import {failedGetAdminList, failedGetMemberList, setAdminList, setMemberList} from "../reducers/member";
import {call, put, take,} from "redux-saga/effects";
import {fetchBusinessList} from "../../lib/api";
import {failedGetBusinessList, setBusinessList} from "../reducers/business";

export function* getBusinessListSaga(action) {
    try {
        const res = yield call(
            fetchBusinessList,
            action.payload.page,
            action.payload.pageSize,
            action.payload.query,
            action.payload.fileName,
        )
        console.log(res.data)
        yield put(setBusinessList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetBusinessList(error));
    }
}
/*
export function* getAdminsSaga(action) {
    try {
        const res = yield call(
            fetchAdmins,
            action.payload.store,
        )
        yield put(setAdminList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetAdminList(error));
    }
}*/
