import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Pagination from "../components/common/Pagination";
import axios from "axios";
import ProgressModal from "../components/common/ProgressModal";
import {getBusinessDBList} from "../redux/reducers/businessDB";
import BusinessDBList from "../components/BusinessDBList";


const BusinessDBContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.businessDB)
    const [searchQuery, setSearchQuery] = useState("");
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: state.titleNo,
            startDate: state.startDate,
            recycle: state.recycle,
        }))
    }, [])


    const handlePageChange = (page) => {
        dispatch(getBusinessDBList({
            page: page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: state.titleNo,
            startDate: state.startDate,
            recycle: state.recycle,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: query,
            titleNo: state.titleNo,
            startDate: state.startDate,
            recycle: state.recycle,
        }))
    }

    const onTitleHandler = (e) => {
        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: parseInt(e.currentTarget.value),
            startDate: state.startDate,
            recycle: state.recycle,
        }))
    }

    const dbTitleDelete = () => {
        if(window.confirm("정말 삭제합니까?")) {
            if(state.titleNo !== 0) {
                axios.post('/admin/businessDBList/db_title_delete', {
                    no: state.titleNo
                }).then((res) => {
                    if (res.data.isDeleted) {
                        alert("삭제완료")
                        window.location.reload();
                    } else {
                        alert("삭제실패")
                    }
                })
            }
            else {
                alert("목록을 선택해주세요")
            }
        }
    }

    const onClickSearch = (e) => {

        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: state.titleNo,
            startDate: state.startDate,
            recycle: state.recycle,
        }))
    }

    const onRefresh = () => {
        dispatch(getBusinessDBList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            titleNo: state.titleNo,
            startDate: state.startDate,
            recycle: state.recycle,
        }))
    }

    const startIndex = ((state.page - 1) * state.pageSize) + 1;
    const currentMaxCount = state.totalCount < (startIndex + state.pageSize - 1)? state.totalCount : (startIndex + state.pageSize - 1)

    return <div>
        {state.isLoading || isWait && (<ProgressModal />)}

        <div className="d-flex">
            <input style={{
                width: "180px"
            }} className="form-control me-1" type="month" id="start_date" value={state.startDate} onChange={ event => {
                dispatch(getBusinessDBList({
                    page: state.page,
                    pageSize: state.pageSize,
                    query: searchQuery,
                    titleNo: state.titleNo,
                    startDate: event.currentTarget.value,
                    recycle: state.recycle,
                }))
            }}/>
            <select className="form-select" onChange={(e) => onTitleHandler(e)} value={state.titleNo} style={{width: "150px"}}>
                <option key={0} value={0}>모든목록</option>
                {state.titles.map((item) => (
                    <option key={item.no} value={item.no}>{`${item.title}(${item.admin.id})`}</option>
                ))}
            </select>
            <button className="btn btn-sm btn-danger mx-1" onClick={dbTitleDelete}>목록 삭제</button>
            {/*<select className="form-select" style={{width: "150px"}} value={state.recycle} onChange={(e) => {
                dispatch(getBusinessDBList({
                    page: state.page,
                    pageSize: state.pageSize,
                    query: searchQuery,
                    titleNo: state.titleNo,
                    startDate: state.startDate,
                    recycle: parseInt(e.currentTarget.value),
                }))
            }}>
                <option value={0}>할당여부</option>
                <option value={1}>미할당</option>
                <option value={2}>할당됨</option>
            </select>*/}
        </div>

        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>

            <select
                className="form-select me-1"
                defaultValue={100}
                style={{
                    width: "100px"
                }}
                value={state.pageSize}
                onChange={e =>
                    dispatch(getBusinessDBList({
                        page: state.page,
                        pageSize: parseInt(e.target.value),
                        query: searchQuery,
                        titleNo: state.titleNo,
                        startDate: state.startDate,
                        recycle: state.recycle,
                    }))
                }
            >
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={300}>300</option>
                <option value={400}>400</option>
                <option value={500}>500</option>
                <option value={600}>600</option>
                <option value={800}>800</option>
                <option value={1000}>1000</option>
                <option value={1500}>1500</option>
                <option value={2000}>2000</option>
                <option value={2500}>2500</option>
                <option value={3000}>3000</option>
                <option value={3500}>3500</option>
                <option value={4000}>4000</option>
                <option value={4500}>4500</option>
                <option value={5000}>5000</option>
                <option value={10000}>10000</option>
            </select>

        </div>

        <div className="d-flex my-1">
            <div style={{
                width: "70%",
                display: "flex"
            }}>
                <div>
                    <p className="h5 ps-2 me-2">회원수: { startIndex + "~" + currentMaxCount + " / " + state.totalCount}</p>
                    {/*{state.blackCount !== 0 && (
                        <p className="ps-2">블랙회원제외수: {state.blackCount}</p>
                    )}*/}
                </div>

                <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange} />
            </div>

            <div className="justify-content-end" style={{
                display: "flex",
                width: "30%"
            }}>
                <div className="input-group me-1 justify-content-end" style={{
                    display: "flex",
                    width: "300px"
                }}>
                    <input style={{
                        width: "220px"
                    }} type="text" className="form-control" id="search_edit" placeholder="검색어를 입력해주세요." onBlur={(e) => {
                        changeQuery(e.target.value)
                    }} onKeyDown={inputPress} />
                    <button style={{
                        width: "80px"
                    }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                </div>

            </div>


        </div>

        <BusinessDBList state={state} onRefresh={onRefresh} />
    </div>
}

export default BusinessDBContainer;