import React, {useEffect, useState} from "react";
import axios from "axios";
import {useSelector} from "react-redux";

const AdminAddComponent = () => {

    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [type, setType] = useState(999);
    const [grade, setGrade] = useState(1);
    const [storeList, setStoreList] = useState([]);
    const [store, setStore] = useState(999);
    const [memo, setMemo] = useState("");

    const userInfo = useSelector((state) => state.userInfo);

    const getStoreList = (type) => {
        axios.post('/admin/admin/get_store_list_add', {
            type: type
        }).then((res) => {
            console.log(res.data)
            setStoreList(res.data.storeList)
        })
    }

    const idChangeHandler = (e) => {
        const currentId = e.currentTarget.value
        setId(currentId)
        const notKorean = /[ \[\]{}()<>?|`~!@#$%^&*_+=,.;:\"'/\\]/g;
        if (notKorean.test(currentId)) {
            alert("한글,영어,숫자조합만 가능합니다")
            setId("")
        }
    }
    const pwChangeHandler = (e) => {
        setPw(e.currentTarget.value);
    }
    const typeHandler = (e) => {
        setType(parseInt(e.currentTarget.value));
        if(parseInt(e.currentTarget.value) !== 999) {
            getStoreList(parseInt(e.currentTarget.value))
        }
    }
    const typeSelectList = [
        {no: "0", value : 0, name : "본사"},
        {no: "1", value : 1, name : "영업"},
        {no: "2", value : 2, name : "리콜"},
    ]
    const gradeHandler = (e) => {
        setGrade(parseInt(e.currentTarget.value));
    }
    const gradeSelectList = [
        {no: 1, value : 1, name : "팀원"},
        {no: 2, value : 2, name : "팀장"},
        {no: 3, value : 3, name : "관리자"},
    ]
    const storeHandler = (e) => {
        setStore(e.currentTarget.value);
    }
    const memoHandler = (e) =>{
        setMemo(e.currentTarget.value);
    }

    const handleRegister = () => {
        if (grade !== 3 && store === 999) {
            alert("영업점 선택바랍니다")
        } else {
            axios.post('/admin/admin/insert', {
                id: id,
                pw: pw,
                type: parseInt(type),
                grade: type === "3"? 3:grade,
                memo: memo,
                store: store
            }).then((res) => {
                if(res.data.isInserted) {
                    alert('가입완료')
                    window.location.reload();
                }
                else if (res.data.error) {
                    alert(res.data.error)
                }
                else {
                    alert('가입실패')
                }
            })
        }
    }
    return (

        <div className="modal fade" id="adminAdd" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" >관리자추가</h1>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating mb-2">
                            <input className="form-control" maxLength="12" type="text" placeholder="아이디" value={id} onChange={idChangeHandler} />
                            <label className="col-form-label">아이디</label>
                        </div>
                        <div className="form-floating mb-2">
                            <input className="form-control" maxLength="12" type="text" placeholder="비밀번호" value={pw} onChange={pwChangeHandler} />
                            <label className="col-form-label">비밀번호</label>
                        </div>
                        <div className="form-floating mb-2">
                            <select className="form-select" onChange={typeHandler} value={type}>
                                <option key={999} value={999}>---사용처 선택---</option>
                                {userInfo.grade === 3 && userInfo.type === 1 && (
                                    <option key={typeSelectList[1].no} value={typeSelectList[1].value}>{typeSelectList[1].name}</option>
                                )}
                                {userInfo.grade === 3 && userInfo.type === 0 && (
                                    <option key={typeSelectList[0].no} value={typeSelectList[0].value}>{typeSelectList[0].name}</option>
                                )}
                                {userInfo.grade === 4 && (
                                    <>
                                        {typeSelectList.map((item, index) => (
                                            <option key={item.no} value={item.value}>{item.name}</option>
                                        ))}
                                    </>
                                )}
                            </select>
                            <label className="col-form-label">사용처</label>
                        </div>
                        {type !== 999 && (
                            <div className="form-floating mb-2">
                                <select className="form-select" onChange={gradeHandler} value={grade}>
                                    {userInfo.grade === 3 && userInfo.type === 1 && (
                                        <>
                                            <option key={gradeSelectList[0].no} value={gradeSelectList[0].value}>{gradeSelectList[0].name}</option>
                                            <option key={gradeSelectList[1].no} value={gradeSelectList[1].value}>{gradeSelectList[1].name}</option>
                                        </>
                                    )}
                                    {userInfo.grade === 3 && userInfo.type === 0 && (
                                        <>
                                            <option key={gradeSelectList[0].no} value={gradeSelectList[0].value}>{gradeSelectList[0].name}</option>
                                        </>
                                    )}
                                    {userInfo.grade === 4 && (
                                        <>
                                            {gradeSelectList.map((item, index) => (
                                                <option key={item.no} value={item.value}>{item.name}</option>
                                            ))}
                                        </>
                                    )}

                                </select>
                                <label className="col-form-label">권한</label>
                            </div>
                        )}

                        {type !== 999 && grade !== 3  && (
                            <div className="form-floating mb-2">
                                <select className="form-select" onChange={storeHandler} value={store}>
                                    <option key={999} value={999}>---영업점선택---</option>
                                    {storeList && storeList.map((item, index) => (
                                        <option key={item.no} value={item.no}>{item.name}</option>
                                    ))}
                                </select>
                                <label className="col-form-label">영업점</label>
                            </div>
                        )}
                        <div className="form-floating">
                            <textarea className="form-control" maxLength="50" type="text" placeholder="메모" value={memo} onChange={memoHandler} />
                            <label className="col-form-label">메모</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                        <button type="button" className="btn btn-primary" onClick={handleRegister}>등록</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AdminAddComponent;