import React, {useEffect, useState} from 'react';
import axios from "axios";
import AdminAddComponent from "../../components/AdminAddComponent";
import {useSelector} from "react-redux";

const Admin = () => {

    const [adminList, setAdminList] = useState([]);
    const [adminCount, setAdminCount] = useState(0);
    const [adminNos, setAdminNos] = useState([]);
    const [query, setQuery] = useState("");

    const [type, setType] = useState(999);
    const [store, setStore] = useState(999);
    const [storeList, setStoreList] = useState([]);

    const [memo, setMemo] = useState("");
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");

    const userInfo = useSelector((state) => state.userInfo);

    const getAdminList = (_type, _store, _query) => {
        axios.post('/admin/admin/get_admin_list', {
            type: userInfo.grade === 4? _type: userInfo.type,
            store: _store,
            query: _query,
            adminNo: userInfo.userNo
        })
            .then((res) => {
            if(res.data.adminList !== null) {
                setAdminList(res.data.adminList)
                setAdminCount(res.data.adminList.length)
            }
        })
    }
    useEffect(() => {
        getAdminList(type, store, query)
    }, [])

    const typeHandler = (e) => {
        setType(parseInt(e.currentTarget.value));

        getStoreList(parseInt(e.currentTarget.value));
        if(userInfo.grade === 4) {
            getAdminList(parseInt(e.currentTarget.value), store, query)
        }
        else if(userInfo.grade === 3) {
            getAdminList(userInfo.type, store, query)
        }

    }

    const typeSelectList = [
        {no: "0", value : 0, name : "본사"},
        {no: "1", value : 1, name : "영업"},
        {no: "2", value : 2, name : "리콜"},
    ]
    const getStoreList = (type) => {
        axios.post('/admin/admin/get_store_list', {
            type: type
        }).then((res) => {
            setStoreList(res.data.storeList)
        })
    }

    const storeListHandler = (e) => {
        setStore(parseInt(e.currentTarget.value))
        getAdminList(type, parseInt(e.currentTarget.value), query)
    }

    const allCheckHandler = (e) => {
        if(adminList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < adminList.length; i++) {
                checks.push(adminList[i].no)
            }
            setAdminNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let adminNos2 = []
        if (checked) {
            adminNos2 = [...adminNos, no]
            setAdminNos([...adminNos, no])
        } else {
            adminNos2 = adminNos.filter((id) => id !== no)
            setAdminNos(adminNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for (let i = 0; i < adminNos2.length; i++) {
            totalCheck += adminList.filter((id) => id.no === adminNos2[i])[0].no
        }
        //console.log(totalCheck)
    }

    const updateState = (state, adminNos) => {
        if(adminNos.length > 0) {
            axios.post('/admin/admin/change_state', {
                adminNos: adminNos,
                state: state
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패")
                window.location.reload()
            })
        }
    }

    const adminDelete = (e) => {
        if(window.confirm("정말 삭제합니까?")){
            if(adminNos.length > 0) {
                axios.post('/admin/admin/admin_delete', {
                    adminNos: adminNos
                }).then((res) => {
                    alert(res.data.isDeleted? "삭제완료" : "삭제실패")
                    window.location.reload()
                })
            }
            else {
                alert('선택해주세요')
            }
        }
    }

    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }
    const searchHandler = (query) => {
        getAdminList(type, store, query)
    }

    function grade(grade) {
        switch(grade) {
            case 1 :
                return "팀원"
            case 2 :
                return "팀장"
            case 3 :
                return "관리자"
            default : return grade
        }
    }

    function adminType(type) {
        switch(type) {
            case 0 :
                return "본사"
            case 1 :
                return "영업"
            case 2 :
                return "리콜"
            case 3 :
                return "관리자"
            default : return type
        }
    }

    function adminState(state) {
        switch(state) {
            case 1 :
                return "사용"
            case 2 :
                return "중지"
            default : return state
        }
    }

    const updateMemo = (no) => {
        if(memo !== "") {
            axios.post('/admin/admin/change_memo', {
                no: no,
                memo: memo
            }).then((res) => {
                alert(res.data.isUpdated? "메모변경완료" : "메모변경실패")
                window.location.reload()
            })
        }
        else {
            alert("변경사항이 없습니다.")
        }

    }
    const updateID = (no) => {
        if(id !== "") {
            axios.post('/admin/admin/change_id', {
                no: no,
                id: id
            }).then((res) => {
                alert(res.data.isUpdated ? "아이디변경완료" : "아이디변경실패")
                window.location.reload()
            })
        }
        else {
            alert("변경사항이 없습니다.")
        }
    }
    const updatePW = (no) => {
        if(pw !== "") {
            axios.post('/admin/admin/change_pw', {
                no: no,
                pw: pw
            }).then((res) => {
                alert(res.data.isUpdated ? "비밀번호변경완료" : "비밀번호변경실패")
                window.location.reload()
            })
        }
        else {
            alert("변경사항이 없습니다.")
        }
    }

    return (
        <div className="userList">
            <div className="d-flex justify-content-between">
                <div className="my-auto">
                    관리자관리
                </div>
                <div className="d-flex justify-content-end w-50">
                    <div className="d-flex my-auto">
                        <select className="form-select" onChange={typeHandler} value={type}>
                            <option key={999} value={999}>---구분---</option>
                            {userInfo.grade === 3 && userInfo.type === 1 && (
                                <option key={typeSelectList[1].no} value={typeSelectList[1].value}>{typeSelectList[1].name}</option>
                            )}
                            {userInfo.grade === 3 && userInfo.type === 0 && (
                                <option key={typeSelectList[0].no} value={typeSelectList[0].value}>{typeSelectList[0].name}</option>
                            )}
                            {userInfo.grade === 4 && (
                                <>
                                    {typeSelectList.map((item, index) => (
                                        <option key={item.no} value={item.value}>{item.name}</option>
                                    ))}
                                </>
                            )}

                        </select>
                        {type !== 999?
                            <select className="form-select" onChange={storeListHandler} value={store} style={{width: "180px"}}>
                                <option key={999} value={999}>---영업점 구분---</option>
                                {storeList? storeList.map((item, index) => (
                                        <option key={item.no} value={item.no}>{item.name}</option>
                                    ))
                                    : <></>}
                            </select>
                            :<></> }
                    </div>
                    <input type="text" className="form-control" maxLength="15" placeholder="검색어를 입력해주세요" style={{width:"35%"}}
                           value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                    <button type="button" className="btn btn-secondary mx-1" style={{width: "60px"}}
                            onClick={() => searchHandler(query)}>검색</button>
                </div>
            </div>
            <div>관리자 수 : {adminList.length > 0? adminList.length : 0}</div>
            <div className="d-flex border mt-2 justify-content-between">
                <div className="d-flex">
                    <button className="btn btn-success mx-1" onClick={() => updateState(1, adminNos)}>관리자사용</button>
                    <button className="btn btn-warning mx-1" onClick={() => updateState(2, adminNos)}>관리자중지</button>

                </div>
                <div>
                    <button className="btn btn-primary mx-1"
                            data-bs-target="#adminAdd" data-bs-toggle="modal">관리자추가</button>
                        <AdminAddComponent/>
                    <button className="btn btn-danger mx-1" onClick={() => adminDelete()}>관리자삭제</button>
                </div>
            </div>
            <table className="table table-bordered table-sm text-center align-middle mt-2">
                <thead className="table-dark">
                <tr>
                    <th style={{width: "3%"}}>
                        <input className="form-check-input" type="checkbox" checked={adminList.length === adminNos.length}
                               onChange={allCheckHandler} />
                    </th>
                    <th>구분</th>
                    <th>지점</th>
                    <th>아이디</th>
                    {userInfo.grade >= 3 ?
                    <th>비밀번호</th>
                        : <></> }
                    <th>권한</th>
                    <th>메모</th>
                    <th style={{width: "7%"}}>상태</th>
                </tr>
                </thead>
                {adminList && adminList.map((admin, index) => (
                <tbody key={admin.no}>
                <tr className="adminList">
                    <td>
                        <input className="form-check-input" type="checkbox"
                               checked={adminNos.filter((no) => no === admin.no).length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, admin.no)}} />
                    </td>
                    <td style={{width: "10%"}}>{adminType(admin.type)}</td>
                    <td>{admin.store.name}</td>
                    <td>
                        <div className="input-group my-auto">
                            <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2"
                                   maxLength="20" placeholder="내용" onChange={(e) => {
                                setId(e.currentTarget.value)
                            }} defaultValue={admin.id}/>
                            <button className="btn btn-success" type="button" id="button-addon2"
                                    onClick={() => updateID(admin.no)}>
                                저장
                            </button>
                        </div>
                    </td>
                    {userInfo.grade >= 3 ?
                        <td>
                            <div className="input-group my-auto">
                                <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2"
                                       maxLength="20" placeholder="내용" onChange={(e) => {
                                    setPw(e.currentTarget.value)
                                }} defaultValue={admin.pw}/>
                                <button className="btn btn-success" type="button" id="button-addon2"
                                        onClick={() => updatePW(admin.no)}>
                                    저장
                                </button>
                            </div>
                        </td>
                        : <></> }
                    <td>{grade(admin.grade)}</td>
                    <td>
                        <div className="input-group my-auto">
                            <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2"
                                   maxLength="20" placeholder="내용" onChange={(e) => {
                                setMemo(e.currentTarget.value)
                            }} defaultValue={admin.memo}/>
                            <button className="btn btn-success" type="button" id="button-addon2"
                                    onClick={() => updateMemo(admin.no)}>
                                저장
                            </button>
                        </div>
                    </td>
                    <td>{adminState(admin.state)}</td>
                </tr>
                </tbody>
                ))}
            </table>
        </div>
    )
}

export default Admin;