import {call, put,} from "redux-saga/effects";
import {fetchBlackList} from "../../lib/api";
import {setBlackList, failedGetBlackList} from "../reducers/black";

export function* getBlackListSaga(action) {
    try {
        const res = yield call(
            fetchBlackList,
            action.payload.page,
            action.payload.pageSize,
            action.payload.query,
            action.payload.fileName,
        )
        console.log(res.data)
        yield put(setBlackList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetBlackList(error));
    }
}
