import {createSlice} from "@reduxjs/toolkit";

export const blackSlice = createSlice({
    name: 'black',
    initialState: {
        isLoading: false,
        isAdminLoading: false,
        isUploadLoading: false,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        memberList: [],
        checkedInputs: [],
        error: null,
        adminError: null,
        uploadError: null,
        query: "",
        stores: [],
        store: 0,
        admins: [],
        admin: 0,
        fileNames: [],
        fileName: "",
    },
    reducers: {
        getBlackList(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
            state.fileName = action.payload.fileName
        },
        setBlackList(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
            state.memberList = action.payload.memberList;
            state.fileNames = action.payload.fileNames;
        },
        failedGetBlackList(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
    },
});

export const {
    getBlackList, setBlackList, failedGetBlackList,
    setCheckedInputs,
} = blackSlice.actions;

export default blackSlice.reducer