import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Pagination from "../components/common/Pagination";
import ProgressModal from "../components/common/ProgressModal";
import BusinessList from "../components/BusinessList";
import {getBusinessList} from "../redux/reducers/business";
import axios from "axios";
import moment from "moment/moment";
import XLSX from "xlsx";


const BusinessContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.business)
    const [searchQuery, setSearchQuery] = useState("");
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        dispatch(getBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            fileName: state.fileName,
        }))
    }, [])


    const handlePageChange = (page) => {
        dispatch(getBusinessList({
            page: page,
            pageSize: state.pageSize,
            query: searchQuery,
            fileName: state.fileName,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: query,
            fileName: state.fileName,
        }))
    }

    const onClickSearch = (e) => {

        dispatch(getBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            fileName: state.fileName,
        }))
    }

    const onRefresh = () => {
        dispatch(getBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            fileName: state.fileName,
        }))
    }
    const fileDeleteHandler = () => {
        if(state.fileName !== "") {
            if(window.confirm(`${state.fileName} 파일을 정말 삭제합니까?`)) {
                setIsWait(true)
                axios.post('/admin/business_upload/file_delete', {
                    fileName: state.fileName
                }).then((res) => {
                    setIsWait(false)
                    console.log(res.data)
                    if (res.data.isDeleted) {
                        window.location.reload();
                    } else {
                        alert('실패')
                    }
                })
            }
        }
        else {
            alert("선택된 파일이 없습니다.")
        }
    }
    const sendBusinessDBListByFileName = (e) => {
        if(state.fileName !== "") {
            setIsWait(true)
            axios.post('/admin/business_upload/send_business_db_by_filename', {
                fileName: state.fileName,
                admin_no: userInfo.userNo
            }).then((res) => {
                setIsWait(false)
                if (res.data.isUpdated) {
                    alert("전송완료")
                    window.location.reload();
                } else if(res.data.error) {
                    alert(res.data.error)
                } else {
                    alert("전송실패")
                }
            })
        }
        else {
            alert("선택된 파일이 없습니다.")
        }
    }

    const excelDown = (e) => {
        setIsWait(true)
        axios.post('/admin/business_upload/getFullList', {
            fileName: state.fileName,
        }).then((res) => {
            setIsWait(false)
            excelDownload(res.data.memberList, "중복제거")
        })
    }
    const excelDownload = (data, fileName) => {
        const excelFileName = `${fileName}_${moment().format("YYYY-MM-DD_HH:mm:ss")}.xlsx`;

        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, excelFileName);
    };

    const startIndex = ((state.page - 1) * state.pageSize) + 1;
    const currentMaxCount = state.totalCount < (startIndex + state.pageSize - 1)? state.totalCount : (startIndex + state.pageSize - 1)

    return <div>
        {state.isLoading || isWait && (<ProgressModal />)}
        <div className="d-flex justify-content-between">
            {/*<div className="d-flex" style={{width: "50%"}}>
                <button className="btn btn-sm btn-warning mx-1" onClick={checkOverlap}>서버중복제거</button>
            </div>*/}

        </div>
        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <select
                className="form-select me-1"
                defaultValue={100}
                style={{
                    width: "100px"
                }}
                value={state.pageSize}
                onChange={e =>
                    dispatch(getBusinessList({
                        page: state.page,
                        pageSize: parseInt(e.target.value),
                        query: searchQuery,
                        fileName: state.fileName,
                    }))
                }
            >
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={300}>300</option>
                <option value={400}>400</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
                <option value={10000}>10000</option>
            </select>
            <select className="form-select me-1" onChange={e =>
                dispatch(getBusinessList({
                    page: state.page,
                    pageSize: state.pageSize,
                    query: searchQuery,
                    fileName: e.target.value,
                }))
            } value={state.fileName} style={{width:"150px"}}>
                <option key={0} value={""}>모든파일명</option>
                {state.fileNames.map((item, index) => (
                    <option key={index} value={item.file_name}>{item.file_name}</option>
                ))}
            </select>

            <button className="btn btn-sm btn-success mx-1" onClick={sendBusinessDBListByFileName}>선택파일전송</button>

            <button className="btn btn-sm btn-danger mx-1" onClick={fileDeleteHandler}>선택파일삭제</button>

            <button className="btn btn-sm btn-success mx-1" onClick={excelDown}>엑셀다운로드</button>
        </div>
        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <div style={{
                width: "70%",
                display: "flex"
            }}>
                <div>
                    <p className="h5 ps-2 me-2">회원수: { startIndex + "~" + currentMaxCount + " / " + state.totalCount}</p>
                </div>


                <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange} />
            </div>

            <div className="justify-content-end" style={{
                display: "flex",
                width: "30%"
            }}>
                <div className="input-group me-1" style={{
                    display: "flex",
                    width: "300px"
                }}>
                    <input style={{
                        width: "220px"
                    }} type="text" className="form-control" id="search_edit" placeholder="검색어를 입력해주세요." onBlur={(e) => {
                        changeQuery(e.target.value)
                    }} onKeyDown={inputPress} />
                    <button style={{
                        width: "80px"
                    }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                </div>
            </div>

        </div>

        <BusinessList state={state} onRefresh={onRefresh} />
    </div>
}

export default BusinessContainer;