import React, {useEffect, useState} from 'react';
import axios from "axios";
import moment from "moment";

const TalkList = () => {

    const [storeList, setStoreList] = useState([]);
    const [storeNos, setStoreNos] = useState([]);

    const getStoreList = () => {
        axios.post('/admin/business_upload/get_talk_list', {

        })
        .then((res) => {
            if(res.data.storeList !== null) {
                setStoreList(res.data.storeList)
            }
        })
    }
    useEffect(() => {
        getStoreList()
    }, [])


    const allCheckHandler = (e) => {
        if(storeList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < storeList.length; i++) {
                checks.push(storeList[i].file_name)
            }
            setStoreNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let storeNos2 = []
        if (checked) {
            storeNos2 = [...storeNos, no]
            setStoreNos([...storeNos, no])
        } else {
            storeNos2 = storeNos.filter((file_name) => file_name !== no)
            setStoreNos(storeNos.filter((file_name) => file_name !== no))
        }
        let totalCheck = 0;
        for (let i = 0; i < storeNos2.length; i++) {
            totalCheck += storeList.filter((id) => id.file_name === storeNos2[i])[0]
        }
    }

    const updateState = (state) => {
        if(storeNos.length > 0) {
            axios.post('/admin/business_upload/change_talk_state', {
                fileName: storeNos,
                state: state
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패")
                getStoreList()
            })
        }
    }
    const deleteFiles = () => {
        if(storeNos.length > 0) {
            if(window.confirm(`${storeNos.length}개의 파일을 정말 삭제합니까?`)) {
                axios.post('/admin/business_upload/delete_talk', {
                    fileName: storeNos
                }).then((res) => {
                    alert(res.data.isDeleted ? "삭제완료" : "삭제실패")
                    getStoreList()
                })
            }
        }
        else {
            alert("선택된 파일이 없습니다.")
        }
    }

    return (
        <div className="userList">
            <div>알림톡파일 수 : {storeList.length}</div>
            <div>
                <button className="btn btn-success mx-1" onClick={() => updateState(1)}>검색허용</button>
                <button className="btn btn-warning mx-1" onClick={() => updateState(2)}>검색중지</button>
                <button className="btn btn-danger mx-1" onClick={deleteFiles}>선택삭제</button>
            </div>
            <table className="table table-bordered table-sm text-center align-middle mt-2">
                <thead className="table-dark">
                <tr>
                    <th style={{width: "3%"}}>
                        <input className="form-check-input" type="checkbox" checked={storeList.length === storeNos.length}
                               onChange={allCheckHandler} />
                    </th>
                    <th>파일명</th>
                    <th>생성일</th>
                    <th style={{width: "10%"}}>상태</th>
                </tr>
                </thead>
                {storeList.map((store, index) => (
                    <tbody key={store.no}>
                    <tr className="adminList">
                        <td>
                            <input className="form-check-input" type="checkbox"
                                   checked={storeNos.filter((file_name) => file_name === store.file_name).length > 0}
                                   onChange={(e) => {checkHandler(e.currentTarget.checked, store.file_name)}}
                            />
                        </td>
                        <td>
                            {store.file_name}
                        </td>
                        <td>{moment(store.createdAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>{store.is_used? "검색허용" : "검색중지"}</td>
                    </tr>
                    </tbody>
                ))}
            </table>
        </div>
    )
}

export default TalkList;