import {useDispatch, useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";
import React, {useState} from "react";
import {getAdminList, setCheckedInputs} from "../redux/reducers/member";
import axios from "axios";
import ProgressModal from "./common/ProgressModal";


const MemberList = ({state, onRefresh}) => {

    const dispatch = useDispatch()
    const [admin, setAdmin] = useState(0);
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    const allCheckHandler = (e) => {
        const checkNos = []
        for(let index = 0; index < state.memberList.length; index++) {
            checkNos.push(state.memberList[index].no)
        }
        dispatch(setCheckedInputs(e.target.checked? checkNos : []));

    }
    const checkHandler = (checked, no) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, no]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== no)));
        }
    }

    const sendMemberList = (e) => {
        if(state.checkedInputs.length > 0) {
            setIsWait(true)
            axios.post('/admin/member_upload/send_member_list_by_check', {
                sendNos: state.checkedInputs,
                admin_no: admin
            }).then((res) => {
                setIsWait(false)
                if(res.data.isUpdated) {
                    alert("전송완료")
                    window.location.reload();
                } else {
                    alert("전송실패")
                }
            })
        } else {
            alert("선택해주세요")
        }
    }

    const onStoreHandler = (e) => {
        dispatch(getAdminList({
            store:parseInt(e.currentTarget.value)
        }))
    }

    const onAdminHandler = (e) => {
        setAdmin(e.currentTarget.value)
    }

    const gradeState = (state) => {
        switch(state) {
            case 1 :
                return "팀원"
            case 2 :
                return "팀장"
            case 3 :
                return "최고관리자"
            default : return state
        }
    }

    const deleteHandler = () => {
        if(state.checkedInputs.length > 0) {
            setIsWait(true)
            axios.post('/admin/member_upload/delete', {
                fileNos: state.checkedInputs
            }).then((res) => {
                setIsWait(false)
                if(res.data.isDeleted) {
                    window.location.reload();
                }
                else {
                    alert('실패')
                }
            })
        }
        else {
            alert("선택된게 없습니다.")
        }
    }
    const deleteHandler2 = () => {
        setIsWait(true)
        axios.post('/admin/member_upload/delete_all', {
            fileNos: state.checkedInputs
        }).then((res) => {
            setIsWait(false)
            if(res.data.isDeleted) {
                window.location.reload();
            }
            else {
                alert('실패')
            }
        })
    }

    return <div>
        {isWait && (<ProgressModal />)}
        <div className="content border excel_body">
            <button className="btn btn-sm btn-danger mx-1" onClick={deleteHandler2}>전체삭제</button>
            <button className="btn btn-sm btn-danger mx-1" onClick={deleteHandler}>선택삭제</button>
            <div className="d-flex ">
                <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                    <thead className="table-dark">
                    <tr>
                        <th style={{width: "30px"}}>
                            <input className="form-check-input" type="checkbox" checked={state.checkedInputs.length === state.memberList.length}
                                   onChange={(e) => allCheckHandler(e)} id="flexCheckDefault"/>
                        </th>
                        <th>번호</th>
                        <th>도메인</th>
                        <th>총판</th>
                        <th>소속</th>
                        <th>소속구분</th>
                        <th>관심회원번호</th>
                        <th>아이디</th>
                        <th>닉네임</th>
                        <th>이름</th>
                        <th>회원상태</th>
                        <th>포인트레벨</th>
                        <th>연락처</th>
                        <th>충전액</th>
                        <th>환전액</th>
                        <th>마진</th>
                        <th>충전일수</th>
                        <th>충전횟수</th>
                        <th>최종충전일시</th>
                        <th>최종사용일시</th>
                        <th>로그인횟수</th>
                        <th>가입일시</th>
                        <th>추천인닉</th>
                        <th style={{
                            width: "300px"
                        }}>메모</th>

                    </tr>
                    </thead>
                    {state.memberList && state.memberList.map((file, index) => (
                        <tbody key={file.no}>
                        <tr style={{
                            height: "100px",
                        }}>
                            <td>
                                <input className="form-check-input" type="checkbox"
                                       checked={state.checkedInputs.includes(file.no)}
                                       onChange={(e) => checkHandler(e.currentTarget.checked, file.no, index)} />
                            </td>
                            <td key={`no_${index}`}>{file.no}</td>
                            <td>{file.domain}</td>
                            <td>{file.partner}</td>
                            <td>{file.belong}</td>
                            <td>{file.belong_detail}</td>
                            <td>{file.interest}</td>
                            <td>{file.id}</td>
                            <td>{file.nickname}</td>
                            <td>{file.name}</td>
                            <td>{file.member_state}</td>
                            <td>{file.member_class}</td>
                            <td>{file.phone}</td>
                            <td>{file.deposit_money}</td>
                            <td>{file.withdraw_money}</td>
                            <td>{file.margin}</td>
                            <td>{file.deposit_day}</td>
                            <td>{file.deposit_number}</td>
                            <td>{file.deposit_recently}</td>
                            <td>{file.recently_login}</td>
                            <td>{file.login_count}</td>
                            <td>{file.join_date}</td>
                            <td>{file.recommend_nick}</td>
                            <td>
                                <div style={{
                                    height: "80px",
                                    overflowY: "scroll",
                                }}>
                                    <p>{file.memo}</p>
                                </div>
                            </td>

                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    </div>


}

export default MemberList