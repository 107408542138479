import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";

const MemberComplete = () => {

    const [memberComplete, setMemberComplete] = useState([]);
    const [completeCount, setCompleteCount] = useState(0);
    const [completeNos, setCompleteNos] = useState([]);
    const [query, setQuery] = useState("");

    const [type, setType] = useState(0);
    const [storeList, setStoreList] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [store, setStore] = useState(0);
    const [admin, setAdmin] = useState(0);
    const [employee, setEmployee] = useState(0);

    const userInfo = useSelector((state) => state.userInfo);
    const completeList = () => {
        axios.post('/admin/member/complete_list', {
            admin_no: userInfo.userNo,
            caller: userInfo.id
        }).then((res) => {
            setMemberComplete(res.data.memberComplete)
            setCompleteCount(res.data.completeCount)
            setCompleteNos([])
        })
    }
    useEffect(completeList, [])

    const getEmployee = () => {
        axios.post('/admin/member/get_employee', {
            admin_no: userInfo.userNo
        }).then((res) => {
            setEmployeeList(res.data.employeeList)
            // console.log(res.data.employeeList)
        })
        getStoreList(2)
    }
    useEffect(getEmployee, [])

    const getStoreList = (type) => {
        axios.post('/admin/member/get_store_list', {
            type: type
        }).then((res) => {
            setStoreList(res.data.storeList)
        })
    }

    const getAdminList = (store) => {
        axios.post('/admin/member/get_admin_list', {
            store_no: store
        }).then((res) => {
            setAdminList(res.data.adminList)
        })
    }

    const onStoreHandler = (e) => {
        setStore(e.currentTarget.value)
        getAdminList(e.currentTarget.value)
    }
    const onAdminHandler = (e) => {
        setAdmin(e.currentTarget.value)
        completeEmployeeList(e.currentTarget.value)
    }
    const onEmployeeHandler = (e) => {
        setEmployee(e.currentTarget.value)
        completeEmployeeList(e.currentTarget.value)
    }

    const completeEmployeeList = (adminNo) => {
        axios.post('/admin/member/complete_employee_list', {
            admin_no: adminNo
        }).then((res) => {
            setMemberComplete(res.data.employeeMemberList)
            setCompleteCount(res.data.employeeMemberCount)
        })
    }

    const completeListDelete = (e) => {
        if(window.confirm("정말 삭제합니까?")) {
            if (completeNos.length > 0) {
                axios.post('/admin/member/delete', {
                    listNo: completeNos
                }).then((res) => {
                    if (res.data.isDeleted) {
                        alert("삭제완료")
                        completeList()
                    } else {
                        alert("실패")
                    }
                })
            } else {
                alert('선택해주세요')
            }
        }
    }

    const allCheckHandler = (e, count) => {
        if (count > memberComplete.length) {
            alert("수량이 부족합니다")
        } else {
            if(memberComplete.length > 0 && e.currentTarget.value) {
                const checks = [];
                for(let i = 0; i < count; i++) {
                    checks.push(memberComplete[i].no)
                }
                setCompleteNos(e.target.checked? checks : [])
            }
        }
    }
    const checkHandler = (checked, no) => {
        let completeNos2 = []
        if(checked) {
            completeNos2 = [...completeNos, no]
            setCompleteNos([...completeNos, no])
        }
        else {
            completeNos2 = completeNos.filter((id) => id !== no)
            setCompleteNos(completeNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < completeNos2.length; i++) {
            totalCheck += memberComplete.filter((id) => id.no === completeNos2[i])[0].no
        }
    }

    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }
    const searchHandler = (query) => {
        axios.post('/admin/member/complete_query', {
            query: query,
            grade: userInfo.grade,
            admin_no: userInfo.userNo
        }).then((res) => {
            if (res.data.queryList !== null) {
                setMemberComplete(res.data.queryList)
                setCompleteCount(res.data.queryCount)
            } else {
                alert("실패")
            }
        })
    }

    const receiveState = (state) => {
        switch(state) {
            case 1 :
                return "bgWait"
            case 2 :
                return "bgComplete"
            case 3 :
                return "bgCancel"
            default : return state
        }
    }

    const gradeState = (state) => {
        switch(state) {
            case 1 :
                return "팀원"
            case 2 :
                return "팀장"
            case 3 :
                return "최고관리자"
            default : return state
        }
    }

    return (
        <div className="userList">
            <div className="d-flex justify-content-between">
                <div className="my-auto w-50">
                    본사완료목록
                </div>
                <div className="d-flex justify-content-end w-50">
                    <div className="d-flex my-auto">
                        {userInfo.grade > 1?
                            <div className="d-flex justify-content-end ">
                                {userInfo.grade > 2?
                                    <>
                                        <select className="form-select" onChange={onStoreHandler} value={store} style={{width: "200px"}}>
                                            <option key={0} value={0}>---영업점선택---</option>
                                            {storeList && storeList.map((item, index) => (
                                                <option key={item.no} value={item.no}>{item.name}</option>
                                            ))}
                                        </select>
                                        {store !== 0?
                                            <select className="form-select" onChange={onAdminHandler} value={admin} style={{width:"45%"}}>
                                                <option key={0} value={0}>---관리자선택---</option>
                                                {adminList && adminList.map((item, index) => (
                                                    <option key={item.no} value={item.no}>{item.id}({gradeState(item.grade)})</option>
                                                ))}
                                            </select>
                                            : <></>}
                                    </>
                                    :
                                    <select className="form-select" onChange={onEmployeeHandler} value={employee} style={{width: "200px"}}>
                                        <option key={0} value={userInfo.userNo}>-----팀원선택-----</option>
                                        {employeeList && employeeList.map((item, index) => (
                                            <option key={item.value} value={item.no}>{item.id}({gradeState(item.grade)})</option>
                                        ))}
                                    </select>
                                }
                            </div>
                            : <></>
                        }
                    </div>
                    <input type="text" className="form-control" maxLength="15" placeholder="검색어를 입력해주세요" style={{width:"35%"}}
                           value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                    <button type="button" className="btn btn-secondary mx-1" style={{width: "70px"}}
                            onClick={() => searchHandler(query)}>검색</button>
                </div>
            </div>
            <div className="d-flex justify-content-between mt-2">
                <span className="ms-2 my-auto" style={{width: "150px"}}>본사회원목록 : {completeCount}명</span>
                <button className="btn btn-danger mx-1" onClick={completeListDelete} style={{width: "70px"}}>삭제</button>
            </div>

            <div className="member_body">
                <div className="content d-flex border mt-2">
                    <table className="table table-bordered table-sm text-center align-middle memberList">
                        <thead className="table-dark">
                        <tr>
                            {userInfo.grade === 3?
                            <th style={{width: "3%"}}>
                                <input className="form-check-input" type="checkbox" checked={memberComplete.length === completeNos.length}
                                       onChange={(e) => allCheckHandler(e, memberComplete.length)} id="flexCheckDefault"/>
                            </th>
                            : <></>}
                            <th>총판</th>
                            <th>소속</th>
                            <th>관심회원번호</th>
                            <th>아이디</th>
                            <th>닉네임</th>
                            <th>이름</th>
                            <th>회원구분</th>
                            <th>회원상태</th>
                            <th>연락처</th>
                            <th>충전일수</th>
                            <th>충전횟수</th>
                            <th>충전액</th>
                            <th>환전액</th>
                            <th>마진</th>
                            <th style={{width: "6%"}}>최근충전일시</th>
                            <th>은행명</th>
                            <th>계좌번호</th>
                            <th style={{width: "6%"}}>가입일시</th>
                            <th>발신자</th>
                        </tr>
                        </thead>
                        {memberComplete && memberComplete.map((member, index) => (
                            <tbody key={member.no} className="border border-dark">
                            <tr className={receiveState(member.receive)} key={member.no}>
                                {userInfo.grade === 3?
                                <td>
                                    <input className="form-check-input" type="checkbox"
                                           checked={completeNos.filter((no) => no === member.no).length > 0}
                                           onChange={(e) => checkHandler(e.currentTarget.checked, member.no)} />
                                </td>
                                : <></>}
                                <td>{member.partner? member.partner : "-"}</td>
                                <td>{member.belong? member.belong : "-"}</td>
                                <td>{member.interest? member.interest : "-"}</td>
                                <td>{member.id? member.id : "-"}</td>
                                <td>{member.nickname? member.nickname : "-"}</td>
                                <td>{member.name? member.name : "-"}</td>
                                <td>{member.member_class? member.member_class : "-"}</td>
                                <td>{member.member_state? member.member_state : "-"}</td>
                                <td>{member.phone? member.phone : "-"}</td>
                                <td>{member.deposit_day? member.deposit_day : "-"}</td>
                                <td>{member.deposit_number? member.deposit_number : "-"}</td>
                                <td>
                                    <NumericFormat value={member.deposit_money} displayType={'text'} thousandSeparator={true} style={{color: "blue"}}/>
                                </td>
                                <td><NumericFormat value={member.withdraw_money} displayType={'text'} thousandSeparator={true} style={{color: "red"}}/></td>
                                <td>
                                    {member.margin >= 0?
                                        <NumericFormat value={member.margin} displayType={'text'} thousandSeparator={true} style={{color: "blue"}}/>
                                        : <NumericFormat value={member.margin} displayType={'text'} thousandSeparator={true} style={{color: "red"}}/>
                                    }
                                </td>
                                <td>{member.deposit_recently? member.deposit_recently : "-"}</td>
                                <td>{member.bank? member.bank : "-"}</td>
                                <td>{member.account? member.account : "-"}</td>
                                <td>{member.join_date? member.join_date : "-"}</td>
                                <td>
                                    {member.caller === null?
                                        <div>-</div>
                                        : <div>{member.caller}</div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                {userInfo.grade === 3?
                                    <td>메모</td>
                                    : <></>}
                                <td colSpan="17">
                                    {member.memo}
                                </td>
                                <td colSpan="2" >
                                    {member.receive === 2 ?
                                        <div style={{color: "blue",fontSize: "15px", fontWeight: "bold"}}> 수신 </div>
                                        :   <div style={{color: "red",fontSize: "15px", fontWeight: "bold"}}> 미수신 </div>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MemberComplete;