import {useDispatch} from "react-redux";
import {NumericFormat} from "react-number-format";
import React, {useState} from "react";


const MemberUploadPreviewList = ({memberList}) => {

    return <div className="content border excel_body">
        <div className="d-flex ">
            <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                <thead className="table-dark">
                <tr key="tr_thead_general">
                    <th>번호</th>
                    <th>도메인</th>
                    <th>총판</th>
                    <th>소속</th>
                    <th>소속구분</th>
                    <th>관심회원번호</th>
                    <th>아이디</th>
                    <th>닉네임</th>
                    <th>이름</th>
                    <th>회원상태</th>
                    <th>포인트레벨</th>
                    <th>연락처</th>
                    <th>충전액</th>
                    <th>환전액</th>
                    <th>마진</th>
                    <th>충전일수</th>
                    <th>충전횟수</th>
                    <th>최종충전일시</th>
                    <th>최종사용일시</th>
                    <th>로그인횟수</th>
                    <th>가입일시</th>
                    <th>추천인닉</th>
                    <th style={{
                        width: "300px"
                    }}>메모</th>
                </tr>
                </thead>
                <tbody>
                {memberList && memberList.map((file, index) => (
                    <tr key={`tr_${index}`}>
                        <td key={`no_${index}`}>{index + 1}</td>
                        <td>{file.domain}</td>
                        <td>{file.partner}</td>
                        <td>{file.belong}</td>
                        <td>{file.belong_detail}</td>
                        <td>{file.interest}</td>
                        <td>{file.id}</td>
                        <td>{file.nickname}</td>
                        <td>{file.name}</td>
                        <td>{file.member_state}</td>
                        <td>{file.member_class}</td>
                        <td>{file.phone}</td>
                        <td>{file.deposit_money}</td>
                        <td>{file.withdraw_money}</td>
                        <td>{file.margin}</td>
                        <td>{file.deposit_day}</td>
                        <td>{file.deposit_number}</td>
                        <td>{file.deposit_recently}</td>
                        <td>{file.recently_login}</td>
                        <td>{file.login_count}</td>
                        <td>{file.join_date}</td>
                        <td>{file.recommend_nick}</td>
                        <td>{file.memo}</td>

                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    </div>
}

export default MemberUploadPreviewList