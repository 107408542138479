import React from 'react';
import {useSelector} from "react-redux";
import {persistor} from "../index";
import {Link} from "react-router-dom";

const Header = () => {

    const userInfo = useSelector((state) => state.userInfo);
    const Home = () => {
        window.location.replace('/')
    }

    const purge = async () => {
        await persistor.purge();
    }

    const onLogoutHandler = (e) => {
        purge()
    }

    function gradeColor (grade) {
        switch (grade) {
            case 1 : return <div className="mx-1" style={{color: "black", fontWeight: "bold"}}>팀원</div>
            case 2 : return <div className="mx-1" style={{color: "green", fontWeight: "bold"}}>팀장</div>
            case 3 : return <div className="mx-1" style={{color: "darkblue", fontWeight: "bold"}}>관리자</div>
            case 4 : return <div className="mx-1" style={{color: "purple", fontWeight: "bold"}}>마스터관리자</div>
            default : return grade
        }
    }

    return (
        <div className="header">
            <div className="d-flex justify-content-between">
                <div className="d-flex">
                    <div className="logo mt-1 px-4" onClick={Home} style={{cursor: "pointer"}}>관리자</div>
                    <div className="my-auto menu">
                        <nav className="navbar navbar-expand-sm mt-1">
                            <div className="navbar-collapse collapse justify-content-center adminMenu">
                                <ul className="navbar-nav">
                                    <li>
                                        <Link className="nav-link" to="/">
                                            홈
                                        </Link>
                                    </li>
                                    {userInfo.type === 1 && (
                                        <>
                                            {userInfo.grade > 2 && (
                                                <>
                                                    <li>
                                                        <Link className="nav-link" to="/user/businessDBList">
                                                            분배가능DB목록
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="nav-link" to="/user/sharedList">
                                                            지점별분배목록
                                                        </Link>
                                                    </li>
                                                    {/*<li>
                                                        <Link className="nav-link" to="/user/businessList">
                                                            영업회원목록
                                                        </Link>
                                                    </li>*/}
                                                </>
                                            )}
                                        </>
                                    )}
                                    {userInfo.grade > 3 && (
                                        <>
                                            <li>
                                                <Link className="nav-link" to="/user/businessDBList">
                                                    분배가능DB목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="/user/sharedList">
                                                    지점별분배목록
                                                </Link>
                                            </li>
                                            {/*<li>
                                                <Link className="nav-link" to="/user/businessList">
                                                    영업회원목록
                                                </Link>
                                            </li>*/}
                                            {/*<li>
                                                <Link className="nav-link" to="/user/memberList">
                                                    본사회원목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="/user/memberComplete">
                                                    본사완료목록
                                                </Link>
                                            </li>*/}
                                        </>
                                    )}
                                    <li>
                                        <Link className="nav-link" to="/board/notice">
                                            공지사항
                                        </Link>
                                    </li>
                                    {userInfo.grade === 1 && (
                                        <>
                                            <li>
                                                <Link className="nav-link" to="/setting/phoneCheck">
                                                    연락처확인
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="nav-link" to="/setting/idCheck">
                                                    본사아이디검색
                                                </Link>
                                            </li>
                                        </>
                                    )}
                                </ul>
                                {userInfo.grade === 4 && (
                                    <div className="dropdown ms-3">
                                        <Link className="nav-link dropdown-toggle" type="button"
                                              data-bs-toggle="dropdown" aria-expanded="false">
                                            환경설정
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="/setting/admin">
                                                    관리자관리
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/store">
                                                    지점관리
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/member_upload">
                                                    본사파일 업로드
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/member">
                                                    본사회원목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/talk_upload">
                                                    알림톡 업로드
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/talk_list">
                                                    알림톡 목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/sms_upload">
                                                    문자영업 업로드
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/sms_list">
                                                    문자영업 목록
                                                </Link>
                                            </li>
                                            {/*<li>
                                                <Link className="dropdown-item" to="/setting/business_check">
                                                    본사&블랙 체크
                                                </Link>
                                            </li>*/}
                                            <li>
                                                <Link className="dropdown-item" to="/setting/business_upload">
                                                    영업파일 업로드
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/business">
                                                    미분배영업목록
                                                </Link>
                                            </li>
                                            {/*<li>
                                                <Link className="dropdown-item" to="/setting/overlap">
                                                    중복영업목록
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/black">
                                                    블랙리스트
                                                </Link>
                                            </li>*/}
                                            <li>
                                                <Link className="dropdown-item" to="/setting/phoneCheck">
                                                    연락처확인
                                                </Link>
                                            </li>
                                            <li>
                                                <Link className="dropdown-item" to="/setting/idCheck">
                                                    본사아이디검색
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                )}
                                {userInfo.grade === 3 && (
                                    <div className="dropdown ms-3">
                                        <Link className="nav-link dropdown-toggle" type="button"
                                              data-bs-toggle="dropdown" aria-expanded="false">
                                            환경설정
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li>
                                                <Link className="dropdown-item" to="/setting/admin">
                                                    관리자관리
                                                </Link>
                                            </li>
                                            {userInfo.type === 1 && (
                                                <li>
                                                    <Link className="dropdown-item" to="/setting/store">
                                                        지점관리
                                                    </Link>
                                                </li>
                                            )}
                                            {userInfo.type === 0 && (
                                                <>
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/member_upload">
                                                            본사파일 업로드
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/member">
                                                            본사회원목록
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/idCheck">
                                                            본사아이디검색
                                                        </Link>
                                                    </li>
                                                </>
                                            )}
                                            {/*
                                        <li>
                                            <Link className="dropdown-item" to="/setting/member">
                                                미분배본사목록
                                            </Link>
                                        </li>*/}
                                            {userInfo.type === 1 && (
                                                <>

                                                    {/*<li>
                                                        <Link className="dropdown-item" to="/setting/business_check">
                                                            본사&블랙 체크
                                                        </Link>
                                                    </li>*/}
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/business_upload">
                                                            영업파일 업로드
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/business">
                                                            미분배영업목록
                                                        </Link>
                                                    </li>
                                                    {/*<li>
                                                        <Link className="dropdown-item" to="/setting/overlap">
                                                            중복영업목록
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/black">
                                                            블랙리스트
                                                        </Link>
                                                    </li>*/}
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/talk_upload">
                                                            알림톡 업로드
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/talk_list">
                                                            알림톡 목록
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/sms_upload">
                                                            문자영업 업로드
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link className="dropdown-item" to="/setting/sms_list">
                                                            문자영업 목록
                                                        </Link>
                                                    </li>
                                                </>
                                            )}

                                            <li>
                                                <Link className="dropdown-item" to="/setting/phoneCheck">
                                                    연락처확인
                                                </Link>
                                            </li>

                                        </ul>
                                    </div>
                                )}
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="my-auto">
                    <div className="d-flex justify-content-center mt-1 me-2">
                        {userInfo.id} 님
                        <span>{gradeColor(userInfo.grade)}</span>
                    </div>
                    <div className="d-flex justify-content-center mt-1 me-2">
                        <button className="btn btn-sm btn-warning" style={{width: "110px"}}
                                onClick={onLogoutHandler}>로그아웃</button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Header;