import React, {useEffect, useState} from 'react';
import moment from "moment";
import axios from "axios";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const Notice = () => {

    const [noticeList, setNoticeList] = useState([]);
    const [noticeCount, setNoticeCount] = useState(0);
    const [noticeNos, setNoticeNos] = useState([]);
    const [title, setTitle] = useState([]);
    const [cont, setCont] = useState([]);
    const [query, setQuery] = useState("");

    const [updates, setUpdates] = useState([]);

    const userInfo = useSelector((state) => state.userInfo);

    const getNoticeList = () => {
        axios.get('/admin/notice/get_notice_list')
        .then((res) => {
            if(res.data.noticeList !== null) {
                const updates2 = []
                for(let i = 0; i < res.data.noticeList.length; i++) {
                    const item = {
                        no: res.data.noticeList[i].no,
                        title: res.data.noticeList[i].title,
                        cont: res.data.noticeList[i].cont,
                    }
                    updates2.push(item)
                }
                setUpdates(updates2)
                setNoticeList(res.data.noticeList)
                setNoticeCount(res.data.noticeCount)
                setNoticeNos([])
            }
        })
    }
    useEffect(getNoticeList, [])


    const onTitleHandler = (e) =>{
        setTitle(e.currentTarget.value);
    }
    const onContHandler = (e) =>{
        setCont(e.currentTarget.value);
    }

    const allCheckHandler = (e) => {
        if(noticeList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < noticeList.length; i++) {
                checks.push(noticeList[i].no)
            }
            setNoticeNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let noticeNos2 = []
        if(checked) {
            noticeNos2 = [...noticeNos, no]
            setNoticeNos([...noticeNos, no])
        }
        else {
            noticeNos2 = noticeNos.filter((id) => id !== no)
            setNoticeNos(noticeNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for(let i = 0; i < noticeNos2.length; i++) {
            totalCheck += noticeList.filter((id) => id.no === noticeNos2[i])[0].no
        }
    }
    const updatesTitleHandler = (value, no) => {
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.title = value
        updates2.push(beforeItem)
        setUpdates(updates2)
    }
    const updatesContHandler = (value, no) => {
        const updates2 = updates.filter((item) => item.no !== no)
        const beforeItem = updates.filter((item) => item.no === no)[0]
        beforeItem.cont = value
        updates2.push(beforeItem)
        setUpdates(updates2)
    }

    const noticeUpdate = (no) => {
        const updatedItem = updates.filter((item) => item.no === no)[0]
        const noticeUpdate = []
        noticeUpdate.push(updatedItem)
        axios.post('/admin/notice/update', {
            noticeList: noticeUpdate,
            adminNo: userInfo.userNo
        }).then((res) => {
            alert(res.data.success? "수정완료" : "수정실패")
            getNoticeList()
        })
    }

    const noticeInsert = () => {
        axios.post('/admin/notice/insert', {
            title: title,
            cont: cont,
            adminNo: userInfo.userNo
        }).then((res) => {
            alert(res.data.isInserted? "등록완료" : "등록실패")
            getNoticeList()
        })
    }

    const noticeDelete = () => {
        axios.post('/admin/notice/delete', {
            noticeNos: noticeNos
        }).then((res) => {
            alert(res.data.isDeleted? "삭제완료" : "삭제실패")
            getNoticeList()
        })
    }
    const queryHandler = (e) => {
        setQuery(e.target.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }

    const searchHandler = (query) => {
        axios.post('/admin/notice/query', {
            query: query,
        }).then((res) => {
            if (res.data.noticeList !== null) {
                setNoticeList(res.data.noticeList)
                setNoticeCount(res.data.noticeCount)
            }
        })
    }

    return (
        <div className="userList">
            <div className="title d-flex">
                공지사항
            </div>
            <div className="d-flex mt-2 justify-content-between">
                <div className="my-auto">
                    <span>공지수 : {noticeCount && noticeCount[0].noticeCount} 개</span>
                </div>
                <div className="d-flex my-auto">
                    <input type="text" className="form-control" maxLength="15" placeholder="검색어를 입력해주세요" style={{width:"200px"}}
                           value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                    <button type="button" className="btn btn-secondary mx-1" style={{width: "70px"}}
                            onClick={() => searchHandler(query)}>검색</button>
                </div>
            </div>
            <table className="table table-bordered border-dark text-center align-middle mt-2">
                <thead className="table-dark">
                <tr>
                    {userInfo && userInfo.grade > 2 ?
                    <th style={{width: "3%"}}>
                        <input className="form-check-input" type="checkbox"
                               checked={noticeList.length === noticeNos.length}
                               onChange={allCheckHandler} />
                    </th>
                    : <></>}
                    <th style={{width: "68%"}}>제목</th>
                    <th style={{width: "8%"}}>작성일자</th>
                    {userInfo && userInfo.grade > 2 ?
                        <>
                    <th style={{width: "8%"}}>수정</th>
                        </>
                        :<></>}
                </tr>
                </thead>
                {noticeList.map((notice, index) => (
                <tbody key={notice.no}>
                <tr>
                    {userInfo && userInfo.grade > 2 ?
                    <td>
                        <input className="form-check-input" type="checkbox"
                               checked={noticeNos.filter((no) => no === notice.no).length > 0}
                               onChange={(e) => {checkHandler(e.currentTarget.checked, notice.no)}} />
                    </td>
                        : <></>}
                    <td>
                        <Link className="nav-link" to={"#noticeTitle" + index} data-bs-toggle="collapse"
                              aria-expanded="true" aria-controls={"noticeTitle" + index}>
                            {notice.title}
                        </Link>
                    </td>
                    <td>
                        {moment(notice.createdAt).format("YYYY-MM-DD HH:mm")}
                    </td>
                    {userInfo && userInfo.grade > 2 ?
                        <>
                    <td>
                        <button className="btn btn-outline-secondary" type="button"
                                data-bs-toggle="collapse" data-bs-target={"#noticeUpdate" + index}
                                aria-expanded="false" aria-controls={"noticeUpdate" + index}>
                            수정
                        </button>
                    </td>
                        </>
                        :<></>}
                </tr>
                <tr className="collapse" id={"noticeTitle" + index}>
                    <td colSpan="5" className="text-start">
                        {notice.cont}
                    </td>
                </tr>
                <tr className="collapse" id={"noticeUpdate" + index}>
                    <td colSpan="5">
                        <div className="update">
                            <div className="form-floating my-3">
                                <input type="text" className="form-control" maxLength="200"
                                       value={updates.filter((item) => item.no === notice.no).length > 0?
                                           updates.filter((item) => item.no === notice.no)[0].title : ""}
                                       onChange={(e) => {updatesTitleHandler(e.currentTarget.value, notice.no)}} />
                                <label className="form-label">제목</label>
                            </div>
                            <div className="form-floating my-3">
                                <textarea className="form-control" maxLength="2000" value={updates.filter((item) => item.no === notice.no).length > 0?
                                          updates.filter((item) => item.no === notice.no)[0].cont : ""} style={{height: "100px"}}
                                          onChange={(e) => {updatesContHandler(e.currentTarget.value, notice.no)}}></textarea>
                                <label className="form-label">내용</label>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-outline-secondary" type="button" onClick={()=> {noticeUpdate(notice.no)}}>
                                    내용수정
                                </button>
                            </div>

                        </div>
                    </td>
                </tr>

                </tbody>
                ))}
            </table>
            {userInfo && userInfo.grade > 2 ?
            <div className="d-flex">
                <button className="btn btn-outline-primary me-2" type="button"
                        data-bs-toggle="collapse" data-bs-target="#addNotice"
                        aria-expanded="false" aria-controls="addNotice">추가
                </button>
                <button className="btn btn-outline-danger" type="button" onClick={noticeDelete}>
                    선택 삭제
                </button>
            </div>
            : <></>}
            <div className="collapse" id="addNotice" >
                <div className="form-floating my-3">
                    <input type="text" className="form-control" placeholder="제목" maxLength="200"
                           value={title} onChange={onTitleHandler} />
                    <label>제목</label>
                </div>
                <div className="form-floating my-3">
                    <textarea className="form-control" placeholder="내용" maxLength="1000"
                              value={cont} onChange={onContHandler}
                        style={{height: "100px"}}></textarea>
                    <label>내용</label>
                </div>

                <div className="text-center">
                    <button className="btn btn-primary" type="button" onClick={() => noticeInsert()}>
                        내용등록
                    </button>
                </div>
            </div>

        </div>
    )
}

export default Notice;