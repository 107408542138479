import {createSlice} from "@reduxjs/toolkit";

export const memberSlice = createSlice({
    name: 'member',
    initialState: {
        isLoading: false,
        isAdminLoading: false,
        isUploadLoading: false,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        memberList: [],
        checkedInputs: [],
        error: null,
        adminError: null,
        uploadError: null,
        query: "",
        stores: [],
        store: 0,
        admins: [],
        admin: 0,
    },
    reducers: {
        getMemberList(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
        },
        setMemberList(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
            state.memberList = action.payload.memberList;
            state.stores = action.payload.stores;
        },
        failedGetMemberList(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        getAdminList(state, action) {
            state.isAdminLoading = true
            state.store = action.payload.store
        },
        setAdminList(state, action) {
            state.isAdminLoading = false
            state.admins = action.payload.admins;
        },
        failedGetAdminList(state, action) {
            state.isAdminLoading = false;
            state.adminError = action.payload
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
        uploadMember(state, action) {
            state.isUploadLoading = true
        },
        resultUpload(state, action) {
            state.isUploadLoading = false
        },
        previewUpload(state, action) {
            state.memberList = action.payload
        }
    },
});

export const {
    getMemberList, setMemberList, failedGetMemberList,
    getAdminList, setAdminList, failedGetAdminList,
    setCheckedInputs, previewUpload,
} = memberSlice.actions;

export default memberSlice.reducer