import {createSlice} from "@reduxjs/toolkit";

export const userBusinessSlice = createSlice({
    name: 'userBusiness',
    initialState: {
        isLoading: false,
        isAdminLoading: false,
        isUploadLoading: false,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        memberList: [],
        checkedInputs: [],
        error: null,
        adminError: null,
        query: "",
        stores: [],
        store: 0,
        admins: [],
        admin: 0,
        blackCount: 0,
        titles: [],
        title: 0,
        receiveType: 0,
    },
    reducers: {
        getUserBusinessList(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
            state.store = action.payload.store
            state.admin = action.payload.admin
            state.title = action.payload.title
            state.receiveType = action.payload.receiveType
        },
        setUserBusinessList(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
            state.blackCount = action.payload.blackCount;
            state.memberList = action.payload.memberList;
            state.stores = action.payload.stores;
            state.titles = action.payload.titles;
        },
        failedGetUserBusinessList(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        getUserAdminList(state, action) {
            state.isAdminLoading = true
            state.store = action.payload.store
        },
        setUserAdminList(state, action) {
            state.isAdminLoading = false
            state.admins = action.payload.admins;
        },
        failedGetUserAdminList(state, action) {
            state.isAdminLoading = false;
            state.adminError = action.payload
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
    },
});

export const {
    getUserBusinessList, setUserBusinessList, failedGetUserBusinessList,
    getUserAdminList, setUserAdminList, failedGetUserAdminList,
    setCheckedInputs,
} = userBusinessSlice.actions;

export default userBusinessSlice.reducer