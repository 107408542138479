import React from 'react';
import SMSUploadContainer from "../../container/SMSUploadContainer";

const sms_upload = () => {

    return (
        <div className="userList">
            <p>문자영업 업로드</p>
            <SMSUploadContainer />
        </div>
    )
}

export default sms_upload;