import React from 'react';
import UserBusinessContainer from "../../container/UserBusinessContainer";

const BusinessList = () => {

    return (
        <div className="userList">
            <p>영업회원목록</p>
            <UserBusinessContainer />
        </div>
    )
}

export default BusinessList;