import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Pagination from "../components/common/Pagination";
import ProgressModal from "../components/common/ProgressModal";
import SharedBusinessList from "../components/SharedBusinessList";
import {getSharedBusinessList} from "../redux/reducers/sharedBusiness";
import axios from "axios";


const SharedBusinessContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.sharedBusiness)
    const [searchQuery, setSearchQuery] = useState("");
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        dispatch(getSharedBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            store: state.store,
            admin: state.admin,
            startDate: state.startDate,
            title: state.title,
            shareType: state.shareType,
            receiveType: state.receiveType,
            returnType: state.returnType,

        }))
    }, [])


    const handlePageChange = (page) => {
        dispatch(getSharedBusinessList({
            page: page,
            pageSize: state.pageSize,
            query: searchQuery,
            store: state.store,
            admin: state.admin,
            startDate: state.startDate,
            title: state.title,
            shareType: state.shareType,
            receiveType: state.receiveType,
            returnType: state.returnType,
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getSharedBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: query,
            store: state.store,
            admin: state.admin,
            startDate: state.startDate,
            title: state.title,
            shareType: state.shareType,
            receiveType: state.receiveType,
            returnType: state.returnType,
        }))
    }

    const onClickSearch = (e) => {

        dispatch(getSharedBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            store: state.store,
            admin: state.admin,
            startDate: state.startDate,
            title: state.title,
            shareType: state.shareType,
            receiveType: state.receiveType,
            returnType: state.returnType,
        }))
    }


    const onRefresh = () => {
        dispatch(getSharedBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            store: state.store,
            admin: state.admin,
            startDate: state.startDate,
            title: state.title,
            shareType: state.shareType,
            receiveType: state.receiveType,
            returnType: state.returnType,
        }))
    }

    const gradeState = (level) => {
        switch(level) {
            case 1 :
                return "팀원"
            case 2 :
                return "팀장"
            case 3 :
                return "최고관리자"
            default : return level
        }
    }

    const onStoreHandler = (e) => {
        dispatch(getSharedBusinessList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
            store: parseInt(e.currentTarget.value),
            admin: 0,
            startDate: state.startDate,
            title: state.title,
            shareType: state.shareType,
            receiveType: state.receiveType,
            returnType: state.returnType,
        }))
    }
    const returnHandler = (e) => {
        if(state.checkedInputs.length > 0) {
            axios.post('/admin/business/returnBySelected', {
                fileNos: state.checkedInputs
            }).then((res) => {
                if(res.data.isReturned) {
                    window.location.reload();
                }
                else {
                    alert('실패')
                }
            })
        }
        else {
            alert("선택해주세요")
        }
    }

    const receiveList = [
        {no: 1, value: 1, name: "대기", color: "#000000"},
        {no: 2, value: 2, name: "부재중", color: "#C9AE00"},
        {no: 3, value: 3, name: "통화완료", color: "#22741C"},
        {no: 4, value: 4, name: "문자발송", color: "#0054FF"},
        {no: 5, value: 5, name: "바로끊음", color: "#5D5D5D"},
        {no: 6, value: 6, name: "나중에전화", color: "#5F00FF"},
        {no: 7, value: 7, name: "결번/정지", color: "#980000"},
        {no: 8, value: 8, name: "기존", color: "#CC723D"},
    ]

    const startIndex = ((state.page - 1) * state.pageSize) + 1;
    const currentMaxCount = state.totalCount < (startIndex + state.pageSize - 1)? state.totalCount : (startIndex + state.pageSize - 1)

    return <div>
        {state.isLoading || isWait && (<ProgressModal />)}
        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <input style={{
                width: "220px"
            }} className="form-control" type="date" id="start_date" value={state.startDate} onChange={ event => {
                dispatch(getSharedBusinessList({
                    page: state.page,
                    pageSize: state.pageSize,
                    query: searchQuery,
                    store: state.store,
                    admin: state.admin,
                    startDate: event.target.value,
                    title: state.title,
                    shareType: state.shareType,
                    receiveType: state.receiveType,
                    returnType: state.returnType,
                }))
            }}/>



            <select className="form-select me-1" onChange={e =>
                dispatch(getSharedBusinessList({
                    page: state.page,
                    pageSize: state.pageSize,
                    query: searchQuery,
                    store: state.store,
                    admin: state.admin,
                    startDate: state.startDate,
                    title: parseInt(e.target.value),
                    shareType: state.shareType,
                    receiveType: state.receiveType,
                    returnType: state.returnType,
                }))
            } value={state.title} style={{width:"180px"}}>
                <option key={0} value={0}>모든목록</option>
                {state.titles.map((item, index) => (
                    <option key={item.no} value={item.no}>{item.title}</option>
                ))}
            </select>

            <select className="form-select me-1" onChange={e => {
                dispatch(getSharedBusinessList({
                    page: state.page,
                    pageSize: state.pageSize,
                    query: searchQuery,
                    store: state.store,
                    admin: state.admin,
                    startDate: state.startDate,
                    title: state.title,
                    shareType: state.shareType,
                    receiveType: state.receiveType,
                    returnType: parseInt(e.currentTarget.value),
                }))
            }} value={state.returnType} style={{width:"180px"}}>
                <option key={"all_returnType"} value={0}>회수여부</option>
                <option key={"noreturn"} value={1}>할당</option>
                <option key={"return"} value={2}>회수</option>
            </select>

            <select className="form-select me-1" onChange={e => {
                dispatch(getSharedBusinessList({
                    page: state.page,
                    pageSize: state.pageSize,
                    query: searchQuery,
                    store: state.store,
                    admin: state.admin,
                    startDate: state.startDate,
                    title: state.title,
                    shareType: parseInt(e.target.value),
                    receiveType: state.receiveType,
                    returnType: state.returnType,
                }))
            }} value={state.shareType} style={{width:"180px"}}>
                <option key={"all_shareType"} value={0}>담당자유무</option>
                <option key={"shared"} value={1}>담당자있음</option>
                <option key={"no_shared"} value={2}>담당자없음</option>
            </select>

            <select className="form-select me-1" onChange={onStoreHandler} value={state.store} style={{width:"180px"}}>
                <option key={0} value={0}>모든영업점</option>
                {state.stores.map((item, index) => (
                    <option key={item.no} value={item.no}>{item.name}</option>
                ))}
            </select>

            {state.shareType === 1 && (
                <select className="form-select me-1" onChange={e => {
                    dispatch(getSharedBusinessList({
                        page: state.page,
                        pageSize: state.pageSize,
                        query: searchQuery,
                        store: state.store,
                        admin: parseInt(e.currentTarget.value),
                        startDate: state.startDate,
                        title: state.title,
                        shareType: state.shareType,
                        receiveType: state.receiveType,
                        returnType: state.returnType,
                    }))
                }} value={state.admin} style={{width:"150px"}}>
                    <option key={0} value={0}>모든담당자</option>
                    {state.admins? state.admins.map((item, index) => (
                            <option key={item.no} value={item.no}>{item.id}({gradeState(item.grade)})</option>
                        ))
                        : <></> }
                </select>
            )}
            {state.shareType === 1 && (
                <select className="form-select" onChange={(e) => {
                    dispatch(getSharedBusinessList({
                        page: state.page,
                        pageSize: state.pageSize,
                        query: searchQuery,
                        store: state.store,
                        admin: state.admin,
                        startDate: state.startDate,
                        title: state.title,
                        shareType: state.shareType,
                        receiveType: parseInt(e.currentTarget.value),
                        returnType: state.returnType,
                    }))
                }} value={state.receiveType} style={{
                    width: "150px",
                    color: state.receiveType === 0? "#000000":receiveList[state.receiveType - 1].color
                }}>
                    <option key={0} value={0}>모든상태</option>
                    {receiveList.map((item) => (
                        <option key={item.no} value={item.value} style={{
                            color: item.color
                        }}>{item.name}</option>
                    ))}
                </select>
            )}



        </div>
        <div className="d-flex my-1">
            <div style={{
                width: "70%",
                display: "flex"
            }}>
                <select
                    className="form-select mx-1"
                    defaultValue={100}
                    style={{
                        width: "100px"
                    }}
                    value={state.pageSize}
                    onChange={e =>
                        dispatch(getSharedBusinessList({
                            page: state.page,
                            pageSize: parseInt(e.target.value),
                            query: searchQuery,
                            store: state.store,
                            admin: state.admin,
                            startDate: state.startDate,
                            title: state.title,
                            shareType: state.shareType,
                            receiveType: state.receiveType,
                            returnType: state.returnType,
                        }))
                    }
                >
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={300}>300</option>
                    <option value={400}>400</option>
                    <option value={500}>500</option>
                    <option value={1000}>1000</option>
                    <option value={10000}>10000</option>
                    <option value={30000}>30000</option>
                </select>
                <div>
                    <p className="h5 ps-2 me-2">회원수: { startIndex + "~" + currentMaxCount + " / " + state.totalCount}</p>
                    {state.blackCount !== 0 && (
                        <p className="ps-2">블랙회원제외수: {state.blackCount}</p>
                    )}
                </div>

                <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange} />
            </div>

            <div className="justify-content-end" style={{
                display: "flex",
                width: "30%"
            }}>
                <div className="input-group me-1 justify-content-end" style={{
                    display: "flex",
                    width: "300px"
                }}>
                    <input style={{
                        width: "220px"
                    }} type="text" className="form-control" id="search_edit" placeholder="검색어를 입력해주세요." onBlur={(e) => {
                        changeQuery(e.target.value)
                    }} onKeyDown={inputPress} />
                    <button style={{
                        width: "80px"
                    }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
                </div>

            </div>


        </div>
        <div>
            <button className="btn btn-sm btn-danger mx-1" onClick={returnHandler}>선택회수</button>
        </div>

        <SharedBusinessList state={state} onRefresh={onRefresh} />
    </div>
}

export default SharedBusinessContainer;