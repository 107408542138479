import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useSelector} from "react-redux";
import {NumericFormat} from "react-number-format";

const MemberList = () => {

    const [memberList, setMemberList] = useState([]);
    const [memberCount, setMemberCount] = useState(0);
    const [memo, setMemo] = useState("");
    const [query, setQuery] = useState("");

    const [type, setType] = useState(0);
    const [storeList, setStoreList] = useState([]);
    const [adminList, setAdminList] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [store, setStore] = useState(0);
    const [admin, setAdmin] = useState(0);
    const [employee, setEmployee] = useState(0);

    const userInfo = useSelector((state) => state.userInfo);
    const getMemberList = () => {
        axios.post('/admin/member/get_member_list', {
            admin_no: userInfo.userNo
        }).then((res) => {
            setMemberList(res.data.memberList)
            setMemberCount(res.data.memberCount)
        })
    }
    useEffect(getMemberList, [])

    const getEmployee = () => {
        axios.post('/admin/member/get_employee', {
            admin_no: userInfo.userNo
        }).then((res) => {
            setEmployeeList(res.data.employeeList)
        })
        getStoreList(2)
    }
    useEffect(getEmployee, [])

    const getStoreList = (type) => {
        axios.post('/admin/member/get_store_list', {
            type: type
        }).then((res) => {
            setStoreList(res.data.storeList)
        })
    }

    const getAdminList = (store) => {
        axios.post('/admin/member/get_admin_list', {
            store_no: store
        }).then((res) => {
            setAdminList(res.data.adminList)
        })
    }

    const onMemoHandler = (e) => {
        setMemo(e.currentTarget.value)
    }
    const updateMemo = (memo, userNo) => {
        axios.post('/admin/member/update_memo', {
            memo: memo,
            admin_no: userInfo.userNo,
            user_no: userNo
        }).then((res) => {
            if (res.data.isUpdated) {
                getMemberList()
            } else {
                alert('저장실패')
            }
        })
    }
    const receiveChangeState = (receive, caller, userNo) => {
        axios.post('/admin/member/receive_change_state', {
            receive: receive,
            caller: caller,
            admin_no: userInfo.userNo,
            user_no: userNo
        }).then((res) => {
            if (res.data.isUpdated) {
                getMemberList()
            } else {
                alert('처리실패')
            }
        })
    }
    const onStoreHandler = (e) => {
        setStore(e.currentTarget.value)
        getAdminList(e.currentTarget.value)
    }
    const onAdminHandler = (e) => {
        setAdmin(e.currentTarget.value)
        getEmployeeMemberList(e.currentTarget.value)
    }
    const onEmployeeHandler = (e) => {
        setEmployee(e.currentTarget.value)
        getEmployeeMemberList(e.currentTarget.value)
    }

    const getEmployeeMemberList = (adminNo) => {
        axios.post('/admin/member/get_enployee_member_list', {
            admin_no: adminNo
        }).then((res) => {
            setMemberList(res.data.employeeMemberList)
            setMemberCount(res.data.employeeMemberCount)
        })
    }

    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }
    const searchHandler = (query) => {
        axios.post('/admin/member/query', {
            query: query,
            grade: userInfo.grade,
            admin_no: userInfo.userNo
        }).then((res) => {
            if (res.data.queryList !== null) {
                setMemberList(res.data.queryList)
            } else {
                alert("실패")
            }
        })
    }

    const gradeState = (state) => {
        switch(state) {
            case 1 :
                return "팀원"
            case 2 :
                return "팀장"
            case 3 :
                return "최고관리자"
            default : return state
        }
    }

    return (
        <div className="userList">
            <div className="d-flex justify-content-between">
                <div className="my-auto w-50">
                    본사회원목록
                </div>
                <div className="d-flex justify-content-end w-50">
                    <div className="d-flex my-auto">
                        {userInfo.grade > 1?
                            <div className="d-flex justify-content-end ">
                                {userInfo.grade > 2?
                                    <>
                                        <select className="form-select" onChange={onStoreHandler} value={store} style={{width: "200px"}}>
                                            <option key={0} value={0}>---영업점선택---</option>
                                            {storeList && storeList.map((item, index) => (
                                                <option key={item.no} value={item.no}>{item.name}</option>
                                            ))}
                                        </select>
                                        {store !== 0?
                                            <select className="form-select" onChange={onAdminHandler} value={admin} style={{width:"45%"}}>
                                                <option key={0} value={0}>---관리자선택---</option>
                                                {adminList && adminList.map((item, index) => (
                                                    <option key={item.no} value={item.no}>{item.id}({gradeState(item.grade)})</option>
                                                ))}
                                            </select>
                                            : <></>}
                                    </>
                                    :
                                    <select className="form-select" onChange={onEmployeeHandler} value={employee} style={{width: "200px"}}>
                                        <option key={0} value={userInfo.userNo}>-----팀원선택-----</option>
                                        {employeeList && employeeList.map((item, index) => (
                                            <option key={item.value} value={item.no}>{item.id}({gradeState(item.grade)})</option>
                                        ))}
                                    </select>
                                }
                            </div>
                            : <></>
                        }
                    </div>
                    <input type="text" className="form-control" maxLength="15" placeholder="검색어를 입력해주세요" style={{width:"35%"}}
                           value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                    <button type="button" className="btn btn-secondary mx-1" style={{width: "70px"}}
                            onClick={() => searchHandler(query)}>검색</button>
                </div>
            </div>
            <div className="d-flex justify-content-between">
                <span className="ms-2 my-auto" style={{width: "150px"}}>본사회원목록 : {memberCount}명</span>
            </div>

            <div className="member_body">
                <div className="content d-flex border mt-2 ">
                    <table className="table table-hover table-bordered table-sm text-center align-middle memberList">
                        <thead className="table-dark">
                        <tr>
                            <th>총판</th>
                            <th>소속</th>
                            <th>관심회원번호</th>
                            <th>아이디</th>
                            <th>닉네임</th>
                            <th>이름</th>
                            <th>회원구분</th>
                            <th>회원상태</th>
                            <th>연락처</th>
                            <th>충전일수</th>
                            <th>충전횟수</th>
                            <th>충전액</th>
                            <th>환전액</th>
                            <th>마진</th>
                            <th style={{width: "6%"}}>최근충전일시</th>
                            <th>은행명</th>
                            <th>계좌번호</th>
                            <th style={{width: "6%"}}>가입일시</th>
                        </tr>
                        </thead>
                        {memberList && memberList.map((member, index) => (
                            <tbody key={member.no} className="border border-dark">
                            <tr>
                                <td>{member.partner? member.partner : "-"}</td>
                                <td>{member.belong? member.belong : "-"}</td>
                                <td>{member.interest? member.interest : "-"}</td>
                                <td>{member.id? member.id : "-"}</td>
                                <td>{member.nickname? member.nickname : "-"}</td>
                                <td>{member.name? member.name : "-"}</td>
                                <td>{member.member_class? member.member_class : "-"}</td>
                                <td>{member.member_state? member.member_state : "-"}</td>
                                <td>{member.phone? member.phone : "-"}</td>
                                <td>{member.deposit_day? member.deposit_day : "-"}</td>
                                <td>{member.deposit_number? member.deposit_number : "-"}</td>
                                <td>
                                    <NumericFormat value={member.deposit_money} displayType={'text'} thousandSeparator={true} style={{color: "blue"}}/>
                                </td>
                                <td><NumericFormat value={member.withdraw_money} displayType={'text'} thousandSeparator={true} style={{color: "red"}}/></td>
                                <td>
                                    {member.margin >= 0?
                                        <NumericFormat value={member.margin} displayType={'text'} thousandSeparator={true} style={{color: "blue"}}/>
                                        : <NumericFormat value={member.margin} displayType={'text'} thousandSeparator={true} style={{color: "red"}}/>
                                    }
                                </td>
                                <td>{member.deposit_recently? member.deposit_recently : "-"}</td>
                                <td>{member.bank? member.bank : "-"}</td>
                                <td>{member.account? member.account : "-"}</td>
                                <td>{member.join_date? member.join_date : "-"}</td>
                            </tr>
                            <tr>
                                <td colSpan="17">
                                    {member.memo === null ?
                                        <div className="input-group my-auto">
                                            <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2"
                                                   maxLength="100" placeholder="메모" onChange={onMemoHandler} defaultValue={member.memo}/>
                                            <button className="btn btn-outline-secondary" type="button" id="button-addon2"
                                                    onClick={() => updateMemo(memo, member.no)}>
                                                저장
                                            </button>
                                        </div>
                                        :
                                        <div className="d-flex">
                                            {member.memo}
                                        </div>
                                    }
                                </td>
                                <td>
                                    {member.receive === 1 ?
                                        <div className="d-flex">
                                            <button className="btn btn-sm btn-primary mx-1" style={{width:"40px"}}
                                                    onClick={() => receiveChangeState(2, userInfo.id, member.no)}>O</button>
                                            <button className="btn btn-sm btn-danger mx-1" style={{width:"40px"}}
                                                    onClick={() => receiveChangeState(3, userInfo.id, member.no)}>X</button>
                                        </div>
                                        : member.receive === 2 ?
                                            <div> 수신 </div>
                                            :   <div> 미수신 </div>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MemberList;