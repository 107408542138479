import {NumericFormat} from "react-number-format";
import React from "react";
import moment from "moment";
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserBusinessList = ({state, onRefresh}) => {
    const receiveList = [
        {no: 1, value: 1, name: "대기", color: "#000000"},
        {no: 2, value: 2, name: "부재중", color: "#C9AE00"},
        {no: 3, value: 3, name: "통화완료", color: "#22741C"},
        {no: 4, value: 4, name: "문자발송", color: "#0054FF"},
        {no: 5, value: 5, name: "바로끊음", color: "#5D5D5D"},
        {no: 6, value: 6, name: "나중에전화", color: "#5F00FF"},
        {no: 7, value: 7, name: "결번/정지", color: "#980000"},
        {no: 8, value: 8, name: "기존", color: "#CC723D"},
    ]

    return <div>
        <div className="content border excel_body">
            <ToastContainer
                position="top-right"
                autoClose={1000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="dark"
            />
            <div className="d-flex ">
                <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                    <thead className="table-dark">
                    <tr>
                        {/*<th style={{width: "30px"}}>
                            <input className="form-check-input" type="checkbox" checked={state.checkedInputs.length === state.memberList.length}
                                   onChange={(e) => allCheckHandler(e)} id="flexCheckDefault"/>
                        </th>*/}
                        <th style={{width: "100px"}}>번호</th>
                        <th>목록명</th>
                        <th>지점</th>
                        <th>담당자</th>
                        <th>연락처</th>
                        <th style={{width: "300px"}}>메모</th>
                        <th>상태</th>
                        <th>배정일</th>
                        <th>수정일</th>
                    </tr>
                    </thead>
                    {state.memberList && state.memberList.map((file, index) => (
                        <tbody key={file.no}>
                        <tr style={{
                            backgroundColor: file.receive === 1? "#FFFFFF" : receiveList[file.receive - 1].color + "33"
                        }}>
                            {/*<td>
                                <input className="form-check-input" type="checkbox"
                                       checked={state.checkedInputs.includes(file.no)}
                                       onChange={(e) => checkHandler(e.currentTarget.checked, file.no, index)} />
                            </td>*/}
                            <td style={{fontSize: "13px"}}>{file.no}</td>
                            <td>{file.businessDBList.businessDBTitle.title}</td>
                            <td>{file.admin.store.name}</td>
                            <td>{file.admin.id}</td>
                            <td onClick={() => {
                                navigator.clipboard.writeText(file.member_upload.phone)
                                toast.success(`${file.member_upload.phone} 복사완료!`, {
                                    transition: Flip
                                })
                            }}>{file.member_upload.phone}</td>
                            <td>{file.memo}</td>
                            <td style={{
                                width: "150px",
                                color: receiveList[file.receive - 1].color
                            }}>{receiveList[file.receive - 1].name}</td>
                            <td>{moment(file.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{moment(file.updatedAt).format("YYYY-MM-DD HH:mm")}</td>
                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    </div>


}

export default UserBusinessList