import {call, put,} from "redux-saga/effects";
import {fetchSharedAdmins, fetchSharedBusinessList} from "../../lib/api";
import {
    failedGetSharedAdminList,
    failedGetSharedBusinessList,
    setSharedAdminList,
    setSharedBusinessList
} from "../reducers/sharedBusiness";

export function* getSharedBusinessListSaga(action) {
    try {
        const res = yield call(
            fetchSharedBusinessList,
            action.payload.page,
            action.payload.pageSize,
            action.payload.query,
            action.payload.store,
            action.payload.admin,
            action.payload.startDate,
            action.payload.title,
            action.payload.shareType,
            action.payload.receiveType,
            action.payload.returnType,
        )
        console.log(res.data)
        yield put(setSharedBusinessList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetSharedBusinessList(error));
    }
}

export function* getSharedAdminListSaga(action) {
    try {
        const res = yield call(
            fetchSharedAdmins,
            action.payload.store,
        )
        yield put(setSharedAdminList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetSharedAdminList(error));
    }
}
