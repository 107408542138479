import React from 'react';
import MemberUploadContainer from "../../container/MemberUploadContainer";

const Member_upload = () => {

    return (
        <div className="userList">
            <p>본사 업로드</p>
            <MemberUploadContainer />
        </div>
    )
}

export default Member_upload;