import React from 'react';
import MemberContainer from "../../container/MemberContainer";

const Member = () => {

    return (
        <div className="userList">
            <p>본사회원목록</p>
            <MemberContainer />
        </div>
    )
}

export default Member;