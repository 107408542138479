import React, {useEffect, useState} from 'react';
import axios from "axios";
import StoreAddComponent from "../../components/StoreAddComponent";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

const Store = () => {

    const [storeList, setStoreList] = useState([]);
    const [query, setQuery] = useState("");

    const [type, setType] = useState(999);
    const [storeNos, setStoreNos] = useState([]);
    const [memo, setMemo] = useState("");
    const [name, setName] = useState("");

    const userInfo = useSelector((state) => state.userInfo);
    const getStoreList = (_type, _query) => {
        axios.post('/admin/store/get_store_list', {
            type: userInfo.grade === 3? userInfo.type: _type,
            query: _query,
        })
        .then((res) => {
            if(res.data.storeList !== null) {
                setStoreList(res.data.storeList)
            }
        })
    }
    useEffect(() => {
        getStoreList(type, query)
    }, [])

    const typeHandler = (e) => {
        setType(parseInt(e.currentTarget.value));
        getStoreList(parseInt(e.currentTarget.value), query);
    }

    const typeSelectList = [
        {no: "0", value : 0, name : "본사"},
        {no: "1", value : 1, name : "영업"},
        {no: "2", value : 2, name : "리콜"},
    ]

    const allCheckHandler = (e) => {
        if(storeList.length > 0 && e.currentTarget.value) {
            const checks = [];
            for(let i = 0; i < storeList.length; i++) {
                checks.push(storeList[i].no)
            }
            setStoreNos(e.target.checked? checks : [])
        }
    }
    const checkHandler = (checked, no) => {
        let storeNos2 = []
        if (checked) {
            storeNos2 = [...storeNos, no]
            setStoreNos([...storeNos, no])
        } else {
            storeNos2 = storeNos.filter((id) => id !== no)
            setStoreNos(storeNos.filter((id) => id !== no))
        }
        let totalCheck = 0;
        for (let i = 0; i < storeNos2.length; i++) {
            totalCheck += storeList.filter((id) => id.no === storeNos2[i])[0].no
        }
    }

    const updateState = (state, storeNos) => {
        if(storeNos.length > 0) {
            axios.post('/admin/store/change_state', {
                storeNos: storeNos,
                state: state
            }).then((res) => {
                alert(res.data.isUpdated? "변경완료" : "변경실패")
                getStoreList(type, query)
            })
        }
    }
    const updateMemo = (storeNo) => {
        if(memo !== "") {
            axios.post('/admin/store/update_memo', {
                storeNo: storeNo,
                memo: memo
            }).then((res) => {
                alert(res.data.isUpdated? "메모변경완료" : "메모변경실패")
                getStoreList(type, query)
            })
        }
        else {
            alert("변경사항이 없습니다.")
        }

    }
    const updateName = (storeNo) => {
        if(name !== "") {
            axios.post('/admin/store/update_name', {
                storeNo: storeNo,
                name: name
            }).then((res) => {
                alert(res.data.isUpdated ? "지점명변경완료" : "지점명변경실패")
                getStoreList(type, query)
            })
        }
        else {
            alert("변경사항이 없습니다.")
        }
    }

    const storeDelete = (e) => {
        if(window.confirm("정말 삭제합니까?")) {
            if (storeNos.length > 0) {
                axios.post('/admin/store/delete', {
                    storeNos: storeNos
                }).then((res) => {
                    alert(res.data.isDeleted ? "삭제완료" : "삭제실패")
                    getStoreList(type, query)
                })
            } else {
                alert('선택해주세요')
            }
        }
    }

    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }
    const searchHandler = (query) => {
        getStoreList(type, query)
    }

    function storeState(state) {
        switch(state) {
            case 1 :
                return "사용"
            case 2 :
                return "중지"
            default : return state
        }
    }

    function storeType(type) {
        switch(type) {
            case 0 :
                return "본사"
            case 1 :
                return "영업"
            case 2 :
                return "리콜"
            case 3 :
                return "관리자"
            default : return type
        }
    }

    return (
        <div className="userList">
            <div className="d-flex justify-content-between">
                <div className="my-auto">
                    지점관리
                </div>
                <div className="d-flex justify-content-end w-50">
                    <div className="d-flex my-auto">
                        <select className="form-select" onChange={typeHandler} value={type}>
                            <option key={999} value={999}>---구분---</option>
                            {userInfo.grade === 3 && userInfo.type === 1 && (
                                <option key={typeSelectList[1].no} value={typeSelectList[1].value}>{typeSelectList[1].name}</option>
                            )}
                            {userInfo.grade === 4 && (
                                <>
                                    {typeSelectList.map((item, index) => (
                                        <option key={item.no} value={item.value}>{item.name}</option>
                                    ))}
                                </>
                            )}
                        </select>

                    </div>
                    <input type="text" className="form-control" maxLength="15" placeholder="검색어를 입력해주세요" style={{width:"35%"}}
                           value={query} onChange={queryHandler} onKeyDown={inputPress}/>
                    <button type="button" className="btn btn-secondary mx-1" style={{width: "60px"}}
                            onClick={() => searchHandler(query)}>검색</button>
                </div>
            </div>
            <div>지점 수 : {storeList.length}</div>
            <div className="d-flex border mt-2 justify-content-between">
                <div>
                    <button className="btn btn-success mx-1" onClick={() => updateState(1, storeNos)}>지점 사용</button>
                    <button className="btn btn-warning mx-1" onClick={() => updateState(2, storeNos)}>지점 중지</button>
                </div>
                <div>

                    <button className="btn btn-primary mx-1"
                        data-bs-target="#storeAdd" data-bs-toggle="modal">지점 추가</button>
                    <StoreAddComponent/>

                    <button className="btn btn-danger mx-1" onClick={() => storeDelete()}>지점 삭제</button>
                </div>
            </div>
            <table className="table table-bordered table-sm text-center align-middle mt-2">
                <thead className="table-dark">
                <tr>
                    <th style={{width: "3%"}}>
                        <input className="form-check-input" type="checkbox" checked={storeList.length === storeNos.length}
                               onChange={allCheckHandler} />
                    </th>
                    <th style={{width: "10%"}}>구분</th>
                    <th>지점 이름</th>
                    <th>메모</th>
                    <th style={{width: "10%"}}>상태</th>
                </tr>
                </thead>
                {storeList.map((store, index) => (
                    <tbody key={store.no}>
                    <tr className="adminList">
                        <td>
                            <input className="form-check-input" type="checkbox"
                                   checked={storeNos.filter((no) => no === store.no).length > 0}
                                   onChange={(e) => {checkHandler(e.currentTarget.checked, store.no)}}
                            />
                        </td>
                        <td>{storeType(store.type)}</td>
                        <td>
                            <div className="input-group my-auto">
                                <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2"
                                       maxLength="20" placeholder="내용" onChange={(e) => {
                                           setName(e.currentTarget.value)
                                }} defaultValue={store.name}/>
                                <button className="btn btn-success" type="button" id="button-addon2"
                                        onClick={() => updateName(store.no)}>
                                    저장
                                </button>
                            </div>
                        </td>
                        <td>
                            <div className="input-group my-auto">
                                <input type="text" className="form-control" aria-label="Recipient's username" aria-describedby="button-addon2"
                                       maxLength="300" placeholder="내용" onChange={(e) => {
                                    setMemo(e.currentTarget.value)
                                }} defaultValue={store.memo}/>
                                <button className="btn btn-success" type="button" id="button-addon2"
                                        onClick={() => updateMemo(store.no)}>
                                    저장
                                </button>
                            </div>
                        </td>
                        <td>{storeState(store.state)}</td>
                    </tr>
                    {/*<tr className="collapse" id={"storeEmployee" + index}>
                        <td colSpan="4">
                            <div className="text-start">영업점 팀원수 : {adminCount} 명</div>
                            <div className="d-flex">
                                <div className="bg-dark text-white" style={{width: "20%"}}>아이디</div>
                                <div className="bg-dark text-white" style={{width: "15%"}}>권한</div>
                                <div className="bg-dark text-white" style={{width: "50%"}}>메모</div>
                                <div className="bg-dark text-white" style={{width: "15%"}}>상태</div>
                            </div>
                            {adminList.length > 0 ?
                                adminList.map((admin, index) => (
                                <div className="d-flex">
                                    <div className="border" style={{width: "20%"}}>{admin.id}</div>
                                    <div className="border" style={{width: "15%"}}>{grade(admin.grade)}</div>
                                    <div className="border" style={{width: "50%"}}>{admin.memo}</div>
                                    <div className="border" style={{width: "15%"}}>{adminState(admin.state)}</div>
                                </div>
                                ))
                                :
                                <div className="d-flex">
                                    <div style={{fontWeight: "bold"}}>해당 영업점에는 팀원이 없습니다</div>
                                </div>
                            }
                        </td>
                    </tr>*/}
                    </tbody>
                ))}
            </table>
        </div>
    )
}

export default Store;