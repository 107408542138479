import React from 'react';
import SearchIDContainer from "../../container/SearchIDContainer";

const SearchID = () => {

    return (
        <div className="userList">
            <p>본사아이디검색</p>
            <SearchIDContainer />
        </div>
    )

}

export default SearchID;