import {createSlice} from "@reduxjs/toolkit";

export const phoneCheckSlice = createSlice({
    name: 'searchID',
    initialState: {
        isLoading: false,
        isSearchLoading: false,
        totalCount: 0,
        error: null,
        searchError: null,
        query: "",
        phoneList: [],
    },
    reducers: {
        searchID(state, action) {
            state.isSearchLoading = true;
            state.query = action.payload.query
        },
        resultSearchID(state, action) {
            state.isSearchLoading = false;
            state.phoneList = action.payload.phoneList
        },
        failedSearchID(state, action) {
            state.isLoading = false;
            state.searchError = action.payload
        }
    },
});

export const {
    searchID, resultSearchID, failedSearchID,
} = phoneCheckSlice.actions;

export default phoneCheckSlice.reducer