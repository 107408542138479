import {createSlice} from "@reduxjs/toolkit";

export const sharedBusinessSlice = createSlice({
    name: 'sharedBusiness',
    initialState: {
        isLoading: false,
        isAdminLoading: false,
        isUploadLoading: false,
        page: 1,
        pageSize: 100,
        totalCount: 0,
        memberList: [],
        checkedInputs: [],
        error: null,
        adminError: null,
        query: "",
        stores: [],
        store: 0,
        admins: [],
        admin: 0,
        blackCount: 0,
        startDate: "",
        titles: [],
        title: 0,
        shareType: 0,
        receiveType: 0,
        returnType: 0,
    },
    reducers: {
        getSharedBusinessList(state, action) {
            state.isLoading = true;
            state.page = action.payload.pageSize === 999? 1: action.payload.page;
            state.pageSize = action.payload.pageSize;
            state.query = action.payload.query
            state.store = action.payload.store
            state.admin = action.payload.admin
            state.startDate = action.payload.startDate
            state.title = action.payload.title
            state.shareType = action.payload.shareType
            state.receiveType = action.payload.receiveType
            state.returnType = action.payload.returnType
        },
        setSharedBusinessList(state, action) {
            state.isLoading = false;
            if((state.page -1) * state.pageSize > action.payload.totalCount) {
                state.page = 1
            }
            state.totalCount = action.payload.totalCount;
            state.blackCount = action.payload.blackCount;
            state.memberList = action.payload.memberList;
            state.stores = action.payload.stores;
            state.admins = action.payload.admins;
            state.titles = action.payload.titles;
        },
        failedGetSharedBusinessList(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        getSharedAdminList(state, action) {
            state.isAdminLoading = true
            state.store = action.payload.store
        },
        setSharedAdminList(state, action) {
            state.isAdminLoading = false
            state.admins = action.payload.admins;
        },
        failedGetSharedAdminList(state, action) {
            state.isAdminLoading = false;
            state.adminError = action.payload
        },
        setCheckedInputs(state, action) {
            state.checkedInputs = action.payload;
        },
    },
});

export const {
    getSharedBusinessList, setSharedBusinessList, failedGetSharedBusinessList,
    getSharedAdminList, setSharedAdminList, failedGetSharedAdminList,
    setCheckedInputs,
} = sharedBusinessSlice.actions;

export default sharedBusinessSlice.reducer