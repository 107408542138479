import {failedGetTotalCount, failedSearchPhone, resultSearchPhone, setTotalCount} from "../reducers/phoneCheck";
import {call, put, take,} from "redux-saga/effects";
import {getTotalCount, searchPhone} from "../../lib/api";

export function* getTotalCountSaga(action) {
    try {
        const res = yield call(getTotalCount)
        console.log(res.data)
        yield put(setTotalCount(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetTotalCount(error));
    }
}
export function* searchPhoneSaga(action) {
    try {
        const res = yield call(searchPhone, action.payload)
        yield put(resultSearchPhone(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedSearchPhone(error));
    }
}