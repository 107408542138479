import {combineReducers} from "redux";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage"

import userInfoReducer from "./userInfo"
import phoneCheckReducer from "./phoneCheck"
import searchIDReducer from "./searchID"
import memberReducer from "./member"
import businessReducer from "./business"
import businessDBReducer from "./businessDB"
import sharedBusinessReducer from "./sharedBusiness"
import userBusinessReducer from "./userBusiness"
import overlapReducer from "./overlap"
import blackReducer from "./black"

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["userInfo"]
}

const rootReducer = combineReducers({
    userInfo: userInfoReducer,
    phoneCheck: phoneCheckReducer,
    searchID: searchIDReducer,
    member: memberReducer,
    business: businessReducer,
    businessDB: businessDBReducer,
    sharedBusiness: sharedBusinessReducer,
    userBusiness: userBusinessReducer,
    overlap: overlapReducer,
    black: blackReducer,
});

export default persistReducer(persistConfig, rootReducer);