import React from "react";


const TalkUploadPreviewList = ({memberList}) => {

    return <div className="content border excel_body">
        <div className="d-flex ">
            <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                <thead className="table-dark">
                <tr key="tr_thead_general_b">
                    <th style={{width: "8%"}}>번호</th>
                    <th>연락처</th>
                    <th>중복횟수</th>
                </tr>
                </thead>
                <tbody>
                {memberList?.length > 0 && (
                    <tr key="tr_user" className="table-secondary">
                        <td key="num">{'번호'}</td>
                        {Object.keys(memberList.filter((row) => row.overlapCount !== undefined).length > 0? memberList.filter((row) => row.overlapCount !== undefined)[0] : memberList[0]).map((name) => (
                            <td key={name}>{name === "overlapCount"? "중복횟수" : name}</td>
                        ))}
                    </tr>
                )}
                {memberList && memberList.map((file, index) => (
                    <tr key={`tr_${index}`}>
                        <td key={`no_${index}`}>{index + 1}</td>
                        {Object.keys(file).map((name) => (
                            <td key={file.name + index}>{file[name]}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    </div>
}

export default TalkUploadPreviewList