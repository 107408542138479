import React from 'react';
import SharedBusinessContainer from "../../container/SharedBusinessContainer";

const SharedList = () => {

    return (
        <div className="userList">
            <p>지점별분배목록</p>
            <SharedBusinessContainer />
        </div>
    )
}

export default SharedList;