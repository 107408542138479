import React from "react";
import {Outlet, Route, Routes} from "react-router-dom";
import {useSelector} from "react-redux";

import Home from "./pages/home";
import Header from "./components/Header";

import User from "./pages/user"
import Board from "./pages/board"
import Setting from "./pages/setting"

import MemberList from "./pages/user/memberList";
import MemberComplete from "./pages/user/memberComplete";
import Member_upload from "./pages/setting/member_upload";
import Member from "./pages/setting/member";
import SharedList from "./pages/user/SharedList"
import BusinessList from "./pages/user/BusinessList"
import BusinessDBList from "./pages/user/BusinessDBList"
import Business_upload from "./pages/setting/business_upload";
import Talk_upload from "./pages/setting/talk_upload";
import TalkList from "./pages/setting/TalkList";
import Sms_upload from "./pages/setting/sms_upload";
import SMSList from "./pages/setting/SMSList";
import Business from "./pages/setting/business";
import Notice from "./pages/board/notice";
import Admin from "./pages/setting/admin"
import Store from "./pages/setting/store";
import PhoneCheck from "./pages/setting/phoneCheck";
import SearchID from "./pages/setting/SearchID";

import LoginModal from "./components/LoginModal";

function App() {

  return (
    <div>
        <Routes>
            <Route element={<Layout />}>

                <Route path="/" element={<Home />}/>

                <Route path="user" element={<User />}>
                    <Route path="sharedList" element={<SharedList />}/>
                    <Route path="businessList" element={<BusinessList />}/>
                    <Route path="businessDBList" element={<BusinessDBList />}/>
                    <Route path="memberList" element={<MemberList />}/>
                    <Route path="memberComplete" element={<MemberComplete />}/>
                </Route>

                <Route path="board" element={<Board />}>
                    <Route path="notice" element={<Notice />}/>
                </Route>

                <Route path="setting" element={<Setting />}>
                    <Route path="admin" element={<Admin />}/>
                    <Route path="member" element={<Member />}/>
                    <Route path="member_upload" element={<Member_upload />}/>
                    <Route path="business" element={<Business />}/>
                    {/*<Route path="overlap" element={<Overlap />}/>
                    <Route path="black" element={<Black />}/>
                    <Route path="business_check" element={<BusinessCheck />}/>*/}
                    <Route path="business_upload" element={<Business_upload />}/>
                    <Route path="talk_upload" element={<Talk_upload />}/>
                    <Route path="talk_list" element={<TalkList />}/>
                    <Route path="sms_upload" element={<Sms_upload />}/>
                    <Route path="sms_list" element={<SMSList />}/>
                    <Route path="store" element={<Store />}/>
                    <Route path="phoneCheck" element={<PhoneCheck />}/>
                    <Route path="idCheck" element={<SearchID />}/>
                </Route>

            </Route>
        </Routes>
    </div>
  );
}

function Layout() {

    const userInfo = useSelector((state) => state.userInfo);

    return (
        <div>
            {userInfo.isLogin?
                <div>
                    <Header />
                    <div>
                        <Outlet />
                    </div>
                </div>
                :
                    <LoginModal />
            }
        </div>
    )
}


export default App;
