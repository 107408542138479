import React from 'react';
import {Outlet} from "react-router-dom";

const Setting = () => {

    return (
        <div className="p-2">
            <div>
                <Outlet />
            </div>
        </div>
    )
}
export default Setting;