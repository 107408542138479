import {call, put,} from "redux-saga/effects";
import {fetchUserAdmins, fetchUserBusinessList} from "../../lib/api";
import {
    failedGetUserAdminList,
    failedGetUserBusinessList,
    setUserAdminList,
    setUserBusinessList
} from "../reducers/userBusiness";

export function* getUserBusinessListSaga(action) {
    try {
        const res = yield call(
            fetchUserBusinessList,
            action.payload.page,
            action.payload.pageSize,
            action.payload.query,
            action.payload.store,
            action.payload.admin,
            action.payload.title,
            action.payload.receiveType,
        )
        console.log(res.data)
        yield put(setUserBusinessList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetUserBusinessList(error));
    }
}

export function* getUserAdminListSaga(action) {
    try {
        const res = yield call(
            fetchUserAdmins,
            action.payload.store,
        )
        yield put(setUserAdminList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetUserAdminList(error));
    }
}
