import {failedGetAdminList, failedGetMemberList, setAdminList, setMemberList} from "../reducers/member";
import {call, put, take,} from "redux-saga/effects";
import {fetchAdmins, fetchMemberList, searchPhone} from "../../lib/api";

export function* getMemberListSaga(action) {
    try {
        const res = yield call(
            fetchMemberList,
            action.payload.page,
            action.payload.pageSize,
            action.payload.query,
        )
        console.log(res.data)
        yield put(setMemberList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetMemberList(error));
    }
}
export function* getAdminsSaga(action) {
    try {
        const res = yield call(
            fetchAdmins,
            action.payload.store,
        )
        yield put(setAdminList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetAdminList(error));
    }
}