import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import ProgressModal from "../components/common/ProgressModal";
import {searchID} from "../redux/reducers/searchID";
import axios from "axios";
import moment from "moment";


const SearchIDContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.searchID)
    const [upload, setUpload] = useState([]);
    const [query, setQuery] = useState("");
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }
    const searchHandler = (query) => {
        if(query.length < 1) {
            alert("아이디(닉네임)2자리이상")
        }
        else {
            dispatch(searchID(query))
        }

    }

    const memberType = (type) => {
        switch(type) {
            case 0 :
                return "본사회원"
            case 1 :
                return "영업 문자발신완료"
            case 2 :
                return "블랙리스트"
            case 3 :
                return "알림톡 발신회원"
            case 4 :
                return "문자 발신회원"
            default : return type
        }
    }

    return <div>
        {state.isLoading || isWait && (<ProgressModal />)}
        <div className="mb-2">

            {!state.isLoading && (
                <>
                    <div className="d-flex justify-content-between">
                        <span> </span>
                        <div className="d-flex">
                            <input type="text" className="form-control" maxLength="15" placeholder="아이디(닉네임)를 입력해주세요"
                                   value={query} onChange={queryHandler} onKeyDown={inputPress} style={{
                                width: "400px"
                            }}/>
                            <button type="button" className="btn btn-secondary mx-1" style={{width: "100px"}}
                                    onClick={() => searchHandler(query)}>검색</button>
                        </div>
                    </div>
                    <div className="d-flex my-2">

                    </div>
                    <div>
                        {state.isSearchLoading && (
                            <p>검색중..</p>
                        )}
                        <div>
                            <p>검색결과</p>
                            <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                                <thead className="table-dark">
                                <tr key="tr_thead_general">
                                    <th>번호</th>
                                    <th>도메인</th>
                                    <th>총판</th>
                                    <th>소속</th>
                                    <th>소속구분</th>
                                    <th>관심회원번호</th>
                                    <th>아이디</th>
                                    <th>닉네임</th>
                                    <th>이름</th>
                                    <th>회원상태</th>
                                    <th>포인트레벨</th>
                                    <th>연락처</th>
                                    <th>충전액</th>
                                    <th>환전액</th>
                                    <th>마진</th>
                                    <th>충전일수</th>
                                    <th>충전횟수</th>
                                    <th>최종충전일시</th>
                                    <th>최종사용일시</th>
                                    <th>로그인횟수</th>
                                    <th>가입일시</th>
                                    <th>추천인닉</th>
                                    <th style={{
                                        width: "300px"
                                    }}>메모</th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.phoneList.length > 0 && state.phoneList.map((file, index) => (
                                    <tr key={`tr_${index}`}>
                                        <td key={`no_${index}`}>{index + 1}</td>
                                        <td>{file.domain}</td>
                                        <td>{file.partner}</td>
                                        <td>{file.belong}</td>
                                        <td>{file.belong_detail}</td>
                                        <td>{file.interest}</td>
                                        <td>{file.id}</td>
                                        <td>{file.nickname}</td>
                                        <td>{file.name}</td>
                                        <td>{file.member_state}</td>
                                        <td>{file.member_class}</td>
                                        <td>{file.phone}</td>
                                        <td>{file.deposit_money}</td>
                                        <td>{file.withdraw_money}</td>
                                        <td>{file.margin}</td>
                                        <td>{file.deposit_day}</td>
                                        <td>{file.deposit_number}</td>
                                        <td>{file.deposit_recently}</td>
                                        <td>{file.recently_login}</td>
                                        <td>{file.login_count}</td>
                                        <td>{file.join_date}</td>
                                        <td>{file.recommend_nick}</td>
                                        <td>{file.memo}</td>

                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </>
            )}

        </div>

    </div>
}

export default SearchIDContainer;