import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import store from "./redux/store/configureStore";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import {BrowserRouter} from "react-router-dom";
export const persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <React.StrictMode>
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>
                  <App />
              </BrowserRouter>
          </PersistGate>
      </Provider>
  </React.StrictMode>

);

