import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import ProgressModal from "../components/common/ProgressModal";
import {getTotalCount, searchPhone} from "../redux/reducers/phoneCheck";
import axios from "axios";
import moment from "moment";


const PhoneCheckContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.phoneCheck)
    const [upload, setUpload] = useState([]);
    const [query, setQuery] = useState("");
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        dispatch(getTotalCount())
    }, [])

    const queryHandler = (e) => {
        setQuery(e.currentTarget.value)
    }
    const inputPress = (e) => {
        if(e.key === "Enter") {
            searchHandler(query)
        }
    }
    const searchHandler = (query) => {
        if(query.length < 11) {
            alert("전화번호 자리 수 를 더 입력해주세요 (9자리 이상)")
        }
        else {
            dispatch(searchPhone(query))
        }

    }

    const memberType = (type) => {
        switch(type) {
            case 0 :
                return "본사회원"
            case 1 :
                return "TM영업한회원"
            case 2 :
                return "블랙리스트"
            case 3 :
                return "알림톡 발신회원"
            case 4 :
                return "문자 발신회원"
            default : return type
        }
    }

    const receiveList = [
        {no: 1, value: 1, name: "대기", color: "#000000"},
        {no: 2, value: 2, name: "부재중", color: "#C9AE00"},
        {no: 3, value: 3, name: "통화완료", color: "#22741C"},
        {no: 4, value: 4, name: "문자발송", color: "#0054FF"},
        {no: 5, value: 5, name: "바로끊음", color: "#5D5D5D"},
        {no: 6, value: 6, name: "나중에전화", color: "#5F00FF"},
        {no: 7, value: 7, name: "결번/정지", color: "#980000"},
        {no: 8, value: 8, name: "기존", color: "#CC723D"},
    ]

    const getBusinessDetail = (item) => {
        let detail = `${item.phone}`;
        for(const data of item.businesses) {
            detail += ` ${moment(data.updatedAt).format("YYYY-MM-DD")} ${data.caller}, ${receiveList[data.receive -1].name},  ${data.memo? data.memo: ""} `
        }
        return detail
    }

    return <div>
        {state.isLoading || isWait && (<ProgressModal />)}
        <div className="mb-2">

            {!state.isLoading && (
                <>
                    <div className="d-flex justify-content-between">
                        <span> </span>
                        <div className="d-flex">
                            <input type="text" className="form-control" maxLength="15" placeholder="번호를 입력해주세요 ex)010-1234-5678"
                                   value={query} onChange={queryHandler} onKeyDown={inputPress} style={{
                                width: "400px"
                            }}/>
                            <button type="button" className="btn btn-secondary mx-1" style={{width: "100px"}}
                                    onClick={() => searchHandler(query)}>검색</button>
                        </div>
                    </div>
                    <div className="d-flex my-2">

                    </div>
                    <div>
                        {state.isSearchLoading && (
                            <p>검색중..</p>
                        )}
                        <div>
                            <p>검색결과</p>
                            <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                                <thead className="table-dark">
                                <tr key="tr_thead_general">
                                    <th>번호</th>
                                    <th>도메인</th>
                                    <th>총판</th>
                                    <th>소속</th>
                                    <th>소속구분</th>
                                    <th>관심회원번호</th>
                                    <th>아이디</th>
                                    <th>닉네임</th>
                                    <th>이름</th>
                                    <th>회원상태</th>
                                    <th>포인트레벨</th>
                                    <th>연락처</th>
                                    <th>충전액</th>
                                    <th>환전액</th>
                                    <th>마진</th>
                                    <th>충전일수</th>
                                    <th>충전횟수</th>
                                    <th>최종충전일시</th>
                                    <th>최종사용일시</th>
                                    <th>로그인횟수</th>
                                    <th>가입일시</th>
                                    <th>추천인닉</th>
                                    <th style={{
                                        width: "300px"
                                    }}>메모</th>
                                </tr>
                                </thead>
                                <tbody>
                            {state.phoneList.length > 0? state.phoneList.map((item, index) => (
                                <>
                                {item.member_type === 0? (
                                    <tr key={`tr_${index}`}>
                                        <td key={`no_${index}`}>{item.no}</td>
                                        <td>{item.domain}</td>
                                        <td>{item.partner}</td>
                                        <td>{item.belong}</td>
                                        <td>{item.belong_detail}</td>
                                        <td>{item.interest}</td>
                                        <td>{item.id}</td>
                                        <td>{item.nickname}</td>
                                        <td>{item.name}</td>
                                        <td>{item.member_state}</td>
                                        <td>{item.member_class}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.deposit_money}</td>
                                        <td>{item.withdraw_money}</td>
                                        <td>{item.margin}</td>
                                        <td>{item.deposit_day}</td>
                                        <td>{item.deposit_number}</td>
                                        <td>{item.deposit_recently}</td>
                                        <td>{item.recently_login}</td>
                                        <td>{item.login_count}</td>
                                        <td>{item.join_date}</td>
                                        <td>{item.recommend_nick}</td>
                                        <td>{item.memo}</td>
                                    </tr>
                                    ):(
                                        <tr className="m-1" key={item.no}><td colSpan='22'>{item.member_type === 1? getBusinessDetail(item) :`${memberType(item.member_type)} ${item.phone} , ${item.file_name}`}</td></tr>
                                    )}
                                </>

                            )) : <tr><td>검색결과가 없습니다.</td></tr>}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </>
            )}

        </div>

    </div>
}

export default PhoneCheckContainer;