import React, {useState} from "react";
import axios from "axios";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../redux/reducers/userInfo"

const LoginModal = () => {
    const dispatch = useDispatch();

    const [id, setId] = useState("");
    const [pw, setPw] = useState("");

    const onIdHandler = (e) => {
        setId(e.currentTarget.value);
    }
    const onPwHandler = (e) => {
        setPw(e.currentTarget.value);
    }

    const onCheckEnter = (e) => {
        if(e.key === 'Enter') {
            onSubmitHandler()
        }
    }

    const onSubmitHandler = (e) => {
        const loginData = {
            id: id,
            pw: pw,
        }
        axios.post('/admin/admin/login', loginData)
            .then((res) => {
            if(res.data.isLogin === true) {
                /*if(res.data.grade === 3) {
                    dispatch(setUserInfo(res.data))
                    window.location.href="/"
                } else {
                    window.location.href="/"
                }*/
                dispatch(setUserInfo(res.data))
                window.location.href="/"
            } else if (res.data.isLogin === false) {
                window.location.href="/"
            } else if (res.data.isStore === false) {
                alert("사용중지된 매장입니다.")
                window.location.href="/"
            } else if (res.data.isAdmin === false) {
                alert("사용중지된 아이디입니다.")
                window.location.href="/"
            }
        })
    }


    return (
        <div className="modal_background">
            <div className="modal_box">
                <div className="mt-2">
                    <input type="text" className="form-control" maxLength="12" value={id} onChange={onIdHandler}
                           placeholder="ID"/>
                    <input type="password" className="form-control" maxLength="12" value={pw} onChange={onPwHandler}
                           placeholder="PW" onKeyDown={onCheckEnter}/>
                </div>
                <div className="mt-2">
                    <button className="btn btn-dark w-100" onClick={onSubmitHandler}>로그인</button>
                </div>
            </div>
        </div>
    )
}

export default LoginModal;