import {all, fork, takeLatest} from 'redux-saga/effects';
import {setUserInfoSaga} from "./userInfo";
import {getTotalCount, searchPhone} from "../reducers/phoneCheck";
import {getTotalCountSaga, searchPhoneSaga} from "./phoneCheck";
import {getAdminList, getMemberList} from "../reducers/member";
import {getAdminsSaga, getMemberListSaga} from "./member";
import {getBusinessList} from "../reducers/business";
import {getBusinessListSaga} from "./business";
import {getBusinessDBList, getDBAdmins} from "../reducers/businessDB";
import {getBusinessDBListSaga, getDBAdminsSaga} from "./businessDB";
import {getUserAdminList, getUserBusinessList} from "../reducers/userBusiness";
import {getUserAdminListSaga, getUserBusinessListSaga} from "./userBusiness";
import {getSharedAdminList, getSharedBusinessList} from "../reducers/sharedBusiness";
import {getSharedAdminListSaga, getSharedBusinessListSaga} from "./sharedBusiness";
import {searchID} from "../reducers/searchID";
import {searchIDSaga} from "./searchID";
import {getOverlapList} from "../reducers/overlap";
import {getOverlapListSaga} from "./overlap";
import {getBlackList} from "../reducers/black";
import {getBlackListSaga} from "./black";

export function* watcherSaga() {
    yield all([
        fork(setUserInfoSaga)
    ])

    yield takeLatest(getTotalCount.type, getTotalCountSaga)
    yield takeLatest(searchPhone.type, searchPhoneSaga)

    yield takeLatest(searchID.type, searchIDSaga)


    //Member
    yield takeLatest(getMemberList.type, getMemberListSaga)
    yield takeLatest(getAdminList.type, getAdminsSaga)

    //Business
    yield takeLatest(getBusinessList.type, getBusinessListSaga)
    //yield takeLatest(getAdminList.type, getAdminsSaga)

    //Overlap
    yield takeLatest(getOverlapList.type, getOverlapListSaga)

    //Black
    yield takeLatest(getBlackList.type, getBlackListSaga)

    //BusinessDB
    yield takeLatest(getBusinessDBList.type, getBusinessDBListSaga)
    yield takeLatest(getDBAdmins.type, getDBAdminsSaga)

    //SharedBusiness
    yield takeLatest(getSharedBusinessList.type, getSharedBusinessListSaga)
    yield takeLatest(getSharedAdminList.type, getSharedAdminListSaga)

    //UserBusiness
    yield takeLatest(getUserBusinessList.type, getUserBusinessListSaga)
    yield takeLatest(getUserAdminList.type, getUserAdminListSaga)


}