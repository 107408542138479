import {createSlice} from "@reduxjs/toolkit";

export const phoneCheckSlice = createSlice({
    name: 'phoneCheck',
    initialState: {
        isLoading: false,
        isSearchLoading: false,
        totalCount: 0,
        error: null,
        searchError: null,
        query: "",
        phoneList: [],
    },
    reducers: {
        getTotalCount(state, action) {
            state.isLoading = true;
        },
        setTotalCount(state, action) {
            state.isLoading = false;
            state.totalCount = action.payload.totalCount? action.payload.totalCount : 0
        },
        failedGetTotalCount(state, action) {
            state.isLoading = false;
            state.error = action.payload
        },
        searchPhone(state, action) {
            state.isSearchLoading = true;
            state.query = action.payload.query
        },
        resultSearchPhone(state, action) {
            state.isSearchLoading = false;
            state.phoneList = action.payload.phoneList
        },
        failedSearchPhone(state, action) {
            state.isLoading = false;
            state.searchError = action.payload
        }
    },
});

export const {
    getTotalCount, setTotalCount, failedGetTotalCount,
    searchPhone, resultSearchPhone, failedSearchPhone,
} = phoneCheckSlice.actions;

export default phoneCheckSlice.reducer