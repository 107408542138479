import {call, put,} from "redux-saga/effects";
import {failedSearchID, resultSearchID} from "../reducers/searchID";
import {searchID} from "../../lib/api";

export function* searchIDSaga(action) {
    try {
        const res = yield call(searchID, action.payload)
        yield put(resultSearchID(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedSearchID(error));
    }
}