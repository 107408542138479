import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import Pagination from "../components/common/Pagination";
import XLSX from "xlsx";
import ProgressModal from "../components/common/ProgressModal";
import axios from "axios";
import SMSUploadPreviewList from "../components/SMSUploadPreviewList";


const SMSUploadContainer = () => {

    const dispatch = useDispatch()
    const [uploadFile, setUploadFile] = useState([])
    const [prevPage, setPrevPage] = useState(0);
    const [prevPageSize, setPrevPageSize] = useState(100);
    const [prevList, setPrevList] = useState([])
    const [prevStartIndex, setPrevStartIndex] = useState(0)
    const [prevCurrentMaxCount, setPrevCurrentMaxCount] = useState(0)
    const [isWait, setIsWait] = useState(false)
    const [isCheck, setIsCheck] = useState(false)
    const [message, setMessage] = useState("")
    const [fileName, setFileName] = useState("")

    const userInfo = useSelector((state) => state.userInfo);


    const handlePrevPageChange = (page) => {
        let startIndex
        if(page === 1) {
            startIndex = 0
        }
        else {
            startIndex = (page - 1) * prevPageSize
        }
        setPrevStartIndex(startIndex + 1)
        setPrevCurrentMaxCount(uploadFile.length < (startIndex + prevPageSize)? uploadFile.length:startIndex + prevPageSize)
        setPrevList(uploadFile.slice(startIndex, startIndex + prevPageSize))
        setPrevPage(page)
    }

    const handleChange = (e) => {
        setIsWait(true)
        setIsCheck(false)
        e.preventDefault();

        if (e.target.files?.length > 0) {
            setFileName(e.target.files[0].name)
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = XLSX.utils.sheet_to_json(replaceWorksheet(worksheet));

                for(const file of json) {
                    file.phone = phoneChange(file.phone)
                }

                checker(json)

            };

            reader.readAsBinaryString(e.target.files[0]);
        }
        setIsWait(false)

    }
    function phoneChange(no) {
        const regex = /[^0-9]/g;
        const onlyNumber = no.toString().replace(regex,"")
        if(onlyNumber.substring(0, 3) === "010") {
            if(onlyNumber.length === 11) {
                const phoneChange = onlyNumber.replace(/^(\d{3})(\d{4})(\d{4})$/, `$1-$2-$3`)
                return phoneChange
            }
            else {
                return ""
            }

        }
        else if(onlyNumber.substring(0, 2) === "10") {
            const no = onlyNumber.substring(2)
            const fullNumber = "010" + no

            if(fullNumber.length === 11) {
                const phoneChange = fullNumber.replace(/^(\d{3})(\d{4})(\d{4})$/, `$1-$2-$3`)
                return phoneChange
            }
            else {
                return ""
            }
        }
        else {
            return ""
        }
    }
    const checker = (json) => {
        setIsWait(true)
        setMessage("")
        const newArray = json.map((row) => [row.phone, row])
        const newMap = new Map(newArray)
        const iterator = newMap.values();
        const phones = [...iterator]
        const overlapArr = json.filter(item => !phones.includes(item))
        for(const item of overlapArr) {
            const index = phones.indexOf(phones.filter(i => i.phone === item.phone)[0])
            phones[index].overlapCount = phones[index].overlapCount !== undefined? phones[index].overlapCount + 1 : 1;
        }

        setUploadFile(phones)
        setPrevPage(0)
        setPrevStartIndex(1)
        setPrevCurrentMaxCount(phones.length < (1 + prevPageSize - 1)? phones.length : prevPageSize)
        setPrevList(phones.slice(0, prevPageSize))

        setIsCheck(true)
        setIsWait(false)
        alert(`${overlapArr.length}개 중복제거완료.`)
        setMessage(`${overlapArr.length}개 중복제거완료.`)

    }

    const insert = () => {
        if(uploadFile.length > 0) {
            setIsWait(true)
            axios.post('/admin/business_upload/sms_insert', {
                uploadFile: uploadFile,
                fileName: fileName,
                admin_no: userInfo.userNo
            }).then((res) => {
                setIsWait(false)
                if(res.data.isInserted) {
                    alert("등록완료")
                    window.location.reload();
                } else {
                    alert("등록실패")
                }
            })
        }
        else {
            alert("업로드할 내용이 없습니다.")
        }

    }

    function replaceWorksheet(workSheet) {
        const regExpAlphabet = /[a-zA-Z ]/g

        const idRegExp = /^id$|^회원아이디$|^회원id$|^로그인id$|^멤버$|^member$|^아이디$/g
        const nickRegExp = /^닉네임$|^별명$|^nick$|^nickname$|^username$/g
        const nameRegExp = /^회원이름$|^이름$|^성함$|^성명$|^name$|^owner$|^실명$|^예금주$/g
        const phoneRegExp = /^번호$|^전화번호$|^연락처$|^핸드폰$|^휴대폰$|^휴대전화$|^폰$|^phone$|^number$|^tel$/g
        const bankRegExp = /^은행$|^거래은행$|^은행명$|^은행이름$|^bankname$/g
        const accountRegExp = /^계좌$|^계좌번호$|^account$/g
        const depositRegExp = /^입금$|^총입금$|^총입금액$|^입금내역$|^충전$|^총충전$|^충전액$|^총충전액$|^충전내역$|^charge$/g
        const withdrawRegExp = /^출금$|^총출금$|^총출금액$|^출금내역$|^환전$|^총환전$|^환전액$|^총환전액$|^환전내역$|^exchange$/g
        const marginRegExp = /^입출$|^입출차액$|^수익$|^이익$|^마진$/g

        for(const key of Object.keys(workSheet)) {
            if(key.replace(regExpAlphabet, "") === "1") {
                if(workSheet[key].v.replace(idRegExp, "id") === "id") {
                    workSheet[key].w = "id"
                }
                else if(workSheet[key].v.replace(nickRegExp, "nickname") === "nickname") {
                    workSheet[key].w = "nickname"
                }
                else if(workSheet[key].v.replace(nameRegExp, "name") === "name") {
                    workSheet[key].w = "name"
                }
                else if(workSheet[key].v.replace(phoneRegExp, "phone") === "phone") {
                    workSheet[key].w = "phone"
                }
                else if(workSheet[key].v.replace(bankRegExp, "bank") === "bank") {
                    workSheet[key].w = "bank"
                }
                else if(workSheet[key].v.replace(accountRegExp, "account") === "account") {
                    workSheet[key].w = "account"
                }
                else if(workSheet[key].v.replace(depositRegExp, "deposit_money") === "deposit_money") {
                    workSheet[key].w = "deposit_money"
                }
                else if(workSheet[key].v.replace(withdrawRegExp, "withdraw_money") === "withdraw_money") {
                    workSheet[key].w = "withdraw_money"
                }
                else if(workSheet[key].v.replace(marginRegExp, "margin") === "margin") {
                    workSheet[key].w = "margin"
                }
            }
        }

        return workSheet

    }

    return <div>
        {isWait && (<ProgressModal />)}
        <div className="d-flex justify-content-between">
            <div className="d-flex" style={{width: "50%"}}>
                <input type="file" className="form-control" name="upload"  id="upload"  style={{width: "250px"}}
                       onChange={handleChange} />

                {isCheck && (
                    <button className="btn btn-sm btn-success mx-1" onClick={insert} >서버 업로드</button>
                )}
                <button className="btn btn-sm btn-danger mx-1" onClick={() => {
                    window.location.reload();
                }} >취소 및 초기화</button>

            </div>

        </div>
        <p className="mt-2">{message}</p>
        {uploadFile.length > 0 && (
            <div className="container-fluid m-1 p-0" style={{
                display: "flex"
            }}>
                <p className="h5 ps-2">
                    { prevStartIndex + "~" + prevCurrentMaxCount + " / " + uploadFile.length}
                </p>

                <select
                    className="form-select me-1"
                    defaultValue={100}
                    style={{
                        width: "100px"
                    }}
                    value={prevPageSize === "999"? "모두" : prevPageSize}
                    onChange={(e) => {
                        setPrevPageSize(parseInt(e.target.value))
                        setPrevPage(1)
                        setPrevList((uploadFile.slice(0, parseInt(e.target.value))))
                        setPrevCurrentMaxCount(uploadFile.length < parseInt(e.target.value)? uploadFile.length : parseInt(e.target.value))
                    }}
                >
                    <option value={100}>100</option>
                    <option value={200}>200</option>
                    <option value={500}>500</option>
                    <option value={10000}>10000</option>
                    <option value={20000}>20000</option>
                </select>

                <Pagination totalCount={uploadFile.length} pageSize={prevPageSize} currentPage={prevPage} onPageChange={handlePrevPageChange} />
            </div>

        )}

        <SMSUploadPreviewList memberList={prevList} />

    </div>
}

export default SMSUploadContainer;