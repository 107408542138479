import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {setCheckedInputs} from "../redux/reducers/business";
import axios from "axios";
import moment from "moment/moment";


const BusinessList = ({state, onRefresh}) => {

    const dispatch = useDispatch()
    const [uploadTitle, setUploadTitle] = useState("");

    const userInfo = useSelector((state) => state.userInfo);


    const allCheckHandler = (e) => {
        const checkNos = []
        for(let index = 0; index < state.memberList.length; index++) {
            checkNos.push(state.memberList[index].no)
        }
        dispatch(setCheckedInputs(e.target.checked? checkNos : []));

    }
    const checkHandler = (checked, no) => {
        if(checked) {
            dispatch(setCheckedInputs([...state.checkedInputs, no]));
        }
        else {
            dispatch(setCheckedInputs(state.checkedInputs.filter((el) => el !== no)));
        }
    }

    const sendBusinessDBList = (e) => {
        if(state.checkedInputs.length > 0) {
            if(uploadTitle !== "") {
                axios.post('/admin/business_upload/send_business_db_by_check', {
                    sendDBNos: state.checkedInputs,
                    uploadTitle: uploadTitle,
                    admin_no: userInfo.userNo
                }).then((res) => {
                    if (res.data.isUpdated) {
                        alert("전송완료")
                        window.location.reload();
                    } else if(res.data.error) {
                        alert(res.data.error)
                    } else {
                        alert("전송실패")
                    }
                })
            }
            else {
                alert("제목을 입력해주세요.")
            }
        }
        else {
            alert("전송할 갯수가 0개 입니다.")
        }
    }

    const onUploadTitle = (e) => {
        setUploadTitle(e.currentTarget.value)
    }

    const deleteHandler = () => {
        if(state.checkedInputs.length > 0) {
            axios.post('/admin/business_upload/delete', {
                fileNos: state.checkedInputs
            }).then((res) => {
                console.log(res.data)
                if(res.data.isDeleted) {
                    window.location.reload();
                }
                else {
                    alert('실패')
                }
            })
        }
        else {
            alert("선택된게 없습니다.")
        }
    }
    function getOverlapCount(overlaps) {
        let count = 0;

        for(const overlap of overlaps) {
            count += overlap.overlap_count
        }
        return count
    }

    return <div>
        <div className="d-flex" style={{
            width: "100%"
        }}>
            <div style={{
                width: "50%"
            }}>
                <button className="btn btn-sm btn-danger mx-1" onClick={deleteHandler}>선택번호삭제</button>
            </div>

            <div className="d-flex justify-content-end" style={{
                width: "100%"
            }}>
                <input className="form-control border border-dark" placeholder="제목" maxLength="12" type="text" style={{width: "130px"}}
                       onChange={onUploadTitle} />
                {/*<input className="form-control border border-dark" placeholder="보낼갯수입력" type="number" style={{width: "180px"}}
                       onChange={writeChange} />*/}
                <button className="btn btn-success mx-1" onClick={sendBusinessDBList}>선택디비전송</button>
            </div>
        </div>

        <div className="content border excel_body">
            <div className="d-flex ">
                <table className="table table-bordered table-sm text-center align-middle mt-2 member_table">
                    <thead className="table-dark">
                    <tr>
                        <th style={{width: "30px"}}>
                            <input className="form-check-input" type="checkbox" checked={state.checkedInputs.length === state.memberList.length}
                                   onChange={(e) => allCheckHandler(e)} id="flexCheckDefault"/>
                        </th>
                        <th style={{width: "8%"}}>번호</th>
                        <th>연락처</th>
                        <th>중복횟수</th>
                        <th>중복파일수</th>
                        <th>파일명</th>
                        <th>업로드일</th>
                        <th>수정일</th>

                    </tr>
                    </thead>
                    {state.memberList && state.memberList.map((file, index) => (
                        <tbody key={file.no}>
                        <tr>
                            <td>
                                <input className="form-check-input" type="checkbox"
                                       checked={state.checkedInputs.includes(file.no)}
                                       onChange={(e) => checkHandler(e.currentTarget.checked, file.no, index)} />
                            </td>
                            <td style={{fontSize: "13px"}}>{file.no}</td>
                            <td>{file.phone}</td>
                            <td>{file.duplicate}</td>
                            <td>{file.overlaps.length > 0? file.overlaps.length: 0}</td>
                            <td>{file.file_name}</td>
                            <td>{moment(file.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                            <td>{moment(file.updatedAt).format("YYYY-MM-DD HH:mm")}</td>

                        </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    </div>


}

export default BusinessList