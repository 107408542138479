import React from 'react';
import TalkUploadContainer from "../../container/TalkUploadContainer";

const Business_upload = () => {

    return (
        <div className="userList">
            <p>알림톡 업로드</p>
            <TalkUploadContainer />
        </div>
    )
}

export default Business_upload;