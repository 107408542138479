import React from 'react';
import BusinessUploadContainer from "../../container/BusinessUploadContainer";

const Business_upload = () => {

    return (
        <div className="userList">
            <p>영업 업로드</p>
            <BusinessUploadContainer />
        </div>
    )
}

export default Business_upload;