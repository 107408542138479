import React from 'react';
import PhoneCheckContainer from "../../container/PhoneCheckContainer";

const PhoneCheck = () => {

    return (
        <div className="userList">
            <p>연락처확인</p>
            <PhoneCheckContainer />
        </div>
    )

}

export default PhoneCheck;