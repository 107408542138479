import React, {useState} from "react";

const ProgressModal = () => {


    return (
        <div className="progress_modal_background">
            <div className="progress_modal_box">
                <p>잠시만 기다려주세요...</p>
            </div>
        </div>
    )
}

export default ProgressModal;