import createSagaMiddleware from 'redux-saga';
import reducer from '../reducers'
import {watcherSaga} from '../sagas'
import {configureStore} from "@reduxjs/toolkit"

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

const store = configureStore({
    reducer: reducer,
    middleware: middlewares,
    devTools: process.env.NODE_ENV !== 'production'
})
// redux-sagas setting
sagaMiddleware.run(watcherSaga);

export default store;
