import {call, put,} from "redux-saga/effects";
import {fetchOverlapList} from "../../lib/api";
import {failedGetOverlapList, setOverlapList} from "../reducers/overlap";

export function* getOverlapListSaga(action) {
    try {
        const res = yield call(
            fetchOverlapList,
            action.payload.page,
            action.payload.pageSize,
            action.payload.query,
            action.payload.fileName,
        )
        console.log(res.data)
        yield put(setOverlapList(res.data))
    } catch (error) {
        console.log(error)
        yield put(failedGetOverlapList(error));
    }
}
