import React, {useEffect, useState} from "react";
import axios from "axios";
import moment from "moment";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";

const Home = () => {

    const [businessStoreList, setBusinessStoreList] = useState([]);

    const [businessStoreReceive, setBusinessStoreReceive] = useState([]);
    const [todayBusinessStoreReceive, setTodayBusinessStoreReceive] = useState([]);

    const [noticeList, setNoticeList] = useState([]);

    const userInfo = useSelector((state) => state.userInfo);

    const getReceive = () => {
        axios.post('/admin/home/get_receive', {
            grade: userInfo.grade,
            admin_no: userInfo.userNo
        }).then((res) => {
            setBusinessStoreList(res.data.result)
        })
    }
    useEffect(getReceive, [])

    const getNoticeList = () => {
        axios.get('/admin/home/get_notice_list')
            .then((res) => {
                setNoticeList(res.data.noticeList)
            })
    }
    useEffect(getNoticeList, [])

    const receiveList = [
        {no: 1, value: 1, name: "대기", color: "#000000"},
        {no: 2, value: 2, name: "부재중", color: "#C9AE00"},
        {no: 3, value: 3, name: "통화완료", color: "#22741C"},
        {no: 4, value: 4, name: "문자발송", color: "#0054FF"},
        {no: 5, value: 5, name: "바로끊음", color: "#5D5D5D"},
        {no: 6, value: 6, name: "나중에전화", color: "#5F00FF"},
        {no: 7, value: 7, name: "결번/정지", color: "#980000"},
        {no: 8, value: 8, name: "기존", color: "#CC723D"},
    ]

    return (
        <div className="p-2">
            홈
            <div className="container-fluid">
                {userInfo.grade >= 3 && [1,2,3].includes(userInfo.type) && (
                    <>
                        <div className="d-flex mt-2">
                            <span className="ms-2 mb-2">-오늘통화건수</span>
                        </div>
                        {[1,3].includes(userInfo.type) && (
                            <div className="d-flex justify-content-around">
                                <table className="table table-bordered border-dark text-center align-middle">
                                    <thead className="table-primary">
                                    <tr>
                                        <th style={{width: "20%"}}>영업점</th>
                                        <th className="text-white" style={{backgroundColor: "#000000"}}>총통화건수</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[5].color}}>나중에전화</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[6].color}}>결번/정지</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[4].color}}>바로끊음</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[7].color}}>기존</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[1].color}}>부재중</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[2].color}}>통화완료</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[3].color}}>문자발송</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[0].color}}>대기</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {businessStoreList.map((store, index) => (
                                        <>
                                            <tr key={store.no}>
                                                <td>
                                                    <Link className="nav-link" to={"#store" + store.no} data-bs-toggle="collapse"
                                                          aria-expanded="true" aria-controls={"store" + store.no}>
                                                        {store.name}
                                                    </Link>
                                                </td>
                                                <td>{store.todayReceiveCount}</td>
                                                <td style={{color: receiveList[5].color}}>
                                                    {
                                                        store.todayReceiveList.filter(item => item.receive === 6).length > 0?
                                                            store.todayReceiveList.filter(item => item.receive === 6)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[6].color}}>
                                                    {
                                                        store.todayReceiveList.filter(item => item.receive === 7).length > 0?
                                                            store.todayReceiveList.filter(item => item.receive === 7)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[4].color}}>
                                                    {
                                                        store.todayReceiveList.filter(item => item.receive === 5).length > 0?
                                                            store.todayReceiveList.filter(item => item.receive === 5)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[7].color}}>
                                                    {
                                                        store.todayReceiveList.filter(item => item.receive === 8).length > 0?
                                                            store.todayReceiveList.filter(item => item.receive === 8)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[1].color}}>
                                                    {
                                                        store.todayReceiveList.filter(item => item.receive === 2).length > 0?
                                                            store.todayReceiveList.filter(item => item.receive === 2)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[2].color}}>
                                                    {
                                                        store.todayReceiveList.filter(item => item.receive === 3).length > 0?
                                                            store.todayReceiveList.filter(item => item.receive === 3)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[3].color}}>
                                                    {
                                                        store.todayReceiveList.filter(item => item.receive === 4).length > 0?
                                                            store.todayReceiveList.filter(item => item.receive === 4)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[0].color}}>
                                                    {
                                                        store.todayReceiveList.filter(item => item.receive === 1).length > 0?
                                                            store.todayReceiveList.filter(item => item.receive === 1)[0].count : 0
                                                    }
                                                </td>
                                            </tr>
                                            {store.adminList.length > 0 && store.adminList.map((admin, index) => (
                                                <tr className="collapse" id={"store" + store.no}>
                                                    <td>
                                                        {admin.name}
                                                    </td>
                                                    <td>{admin.todayReceiveCount}</td>
                                                    <td style={{color: receiveList[5].color}}>
                                                        {
                                                            admin.todayReceiveList.filter(item => item.receive === 6).length > 0?
                                                                admin.todayReceiveList.filter(item => item.receive === 6)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[6].color}}>
                                                        {
                                                            admin.todayReceiveList.filter(item => item.receive === 7).length > 0?
                                                                admin.todayReceiveList.filter(item => item.receive === 7)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[4].color}}>
                                                        {
                                                            admin.todayReceiveList.filter(item => item.receive === 5).length > 0?
                                                                admin.todayReceiveList.filter(item => item.receive === 5)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[7].color}}>
                                                        {
                                                            admin.todayReceiveList.filter(item => item.receive === 8).length > 0?
                                                                admin.todayReceiveList.filter(item => item.receive === 8)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[1].color}}>
                                                        {
                                                            admin.todayReceiveList.filter(item => item.receive === 2).length > 0?
                                                                admin.todayReceiveList.filter(item => item.receive === 2)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[2].color}}>
                                                        {
                                                            admin.todayReceiveList.filter(item => item.receive === 3).length > 0?
                                                                admin.todayReceiveList.filter(item => item.receive === 3)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[3].color}}>
                                                        {
                                                            admin.todayReceiveList.filter(item => item.receive === 4).length > 0?
                                                                admin.todayReceiveList.filter(item => item.receive === 4)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[0].color}}>
                                                        {
                                                            admin.todayReceiveList.filter(item => item.receive === 1).length > 0?
                                                                admin.todayReceiveList.filter(item => item.receive === 1)[0].count : 0
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {/*{[2,3].includes(userInfo.type) && (
                                <div className="d-flex justify-content-around">
                                    <table className="table table-bordered border-dark text-center align-middle">
                                        <thead className="table-success">
                                        <tr>
                                            <th style={{width: "20%"}}>리콜팀</th>
                                            <th>총통화건수</th>
                                            <th>수신통화건수</th>
                                            <th>미수신통화건수</th>
                                            <th>통화완료</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {memberStoreList.map((store) => (
                                            <tr key={store.no}>
                                                <td>{store.name}</td>
                                                <td>
                                                    {todayMemberStoreReceive.map((todayReceive) => (
                                                        store.no === todayReceive.store_no? todayReceive.receiveSum : ""
                                                    ))}
                                                </td>
                                                <td>
                                                    {todayMemberStoreReceive.map((todayReceive) => (
                                                        store.no === todayReceive.store_no? todayReceive.todayReceiveCount : ""
                                                    ))}
                                                </td>
                                                <td>
                                                    {todayMemberStoreReceive.map((todayReceive) => (
                                                        store.no === todayReceive.store_no? todayReceive.todayUnReceiveCount : ""
                                                    ))}
                                                </td>
                                                <td>
                                                    {todayMemberStoreReceive.map((todayReceive) => (
                                                        store.no === todayReceive.store_no? todayReceive.todayJoinCount : ""
                                                    ))}
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                        )}*/}
                        <div className="d-flex mt-2">
                            <span className="ms-2 mb-2">-통화건수</span>
                        </div>
                        {[1,3].includes(userInfo.type) && (
                            <div className="d-flex justify-content-around">
                                <table className="table table-bordered border-dark text-center align-middle">
                                    <thead className="table-primary">
                                    <tr>
                                        <th style={{width: "20%"}}>영업점</th>
                                        <th className="text-white" style={{backgroundColor: "#000000"}}>총통화건수</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[5].color}}>나중에전화</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[6].color}}>결번/정지</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[4].color}}>바로끊음</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[7].color}}>기존</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[1].color}}>부재중</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[2].color}}>통화완료</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[3].color}}>문자발송</th>
                                        <th className="text-white" style={{backgroundColor: receiveList[0].color}}>대기</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {businessStoreList.map((store) => (
                                        <>
                                            <tr key={store.no}>
                                                <td>
                                                    <Link className="nav-link" to={"#storeAll" + store.no} data-bs-toggle="collapse"
                                                          aria-expanded="true" aria-controls={"storeAll" + store.no}>
                                                        {store.name}
                                                    </Link>
                                                </td>
                                                <td>{store.receiveCount}</td>
                                                <td style={{color: receiveList[5].color}}>
                                                    {
                                                        store.receiveList.filter(item => item.receive === 6).length > 0 ?
                                                            store.receiveList.filter(item => item.receive === 6)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[6].color}}>
                                                    {
                                                        store.receiveList.filter(item => item.receive === 7).length > 0 ?
                                                            store.receiveList.filter(item => item.receive === 7)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[4].color}}>
                                                    {
                                                        store.receiveList.filter(item => item.receive === 5).length > 0 ?
                                                            store.receiveList.filter(item => item.receive === 5)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[7].color}}>
                                                    {
                                                        store.receiveList.filter(item => item.receive === 8).length > 0 ?
                                                            store.receiveList.filter(item => item.receive === 8)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[1].color}}>
                                                    {
                                                        store.receiveList.filter(item => item.receive === 2).length > 0 ?
                                                            store.receiveList.filter(item => item.receive === 2)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[2].color}}>
                                                    {
                                                        store.receiveList.filter(item => item.receive === 3).length > 0 ?
                                                            store.receiveList.filter(item => item.receive === 3)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[3].color}}>
                                                    {
                                                        store.receiveList.filter(item => item.receive === 4).length > 0 ?
                                                            store.receiveList.filter(item => item.receive === 4)[0].count : 0
                                                    }
                                                </td>
                                                <td style={{color: receiveList[0].color}}>
                                                    {
                                                        store.receiveList.filter(item => item.receive === 1).length > 0 ?
                                                            store.receiveList.filter(item => item.receive === 1)[0].count : 0
                                                    }
                                                </td>
                                            </tr>
                                            {store.adminList.length > 0 && store.adminList.map((admin, index) => (
                                                <tr className="collapse" id={"storeAll" + store.no}>
                                                    <td>
                                                        {admin.name}
                                                    </td>
                                                    <td>{admin.receiveCount}</td>
                                                    <td style={{color: receiveList[5].color}}>
                                                        {
                                                            admin.receiveList.filter(item => item.receive === 6).length > 0?
                                                                admin.receiveList.filter(item => item.receive === 6)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[6].color}}>
                                                        {
                                                            admin.receiveList.filter(item => item.receive === 7).length > 0?
                                                                admin.receiveList.filter(item => item.receive === 7)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[4].color}}>
                                                        {
                                                            admin.receiveList.filter(item => item.receive === 5).length > 0?
                                                                admin.receiveList.filter(item => item.receive === 5)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[7].color}}>
                                                        {
                                                            admin.receiveList.filter(item => item.receive === 8).length > 0?
                                                                admin.receiveList.filter(item => item.receive === 8)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[1].color}}>
                                                        {
                                                            admin.receiveList.filter(item => item.receive === 2).length > 0?
                                                                admin.receiveList.filter(item => item.receive === 2)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[2].color}}>
                                                        {
                                                            admin.receiveList.filter(item => item.receive === 3).length > 0?
                                                                admin.receiveList.filter(item => item.receive === 3)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[3].color}}>
                                                        {
                                                            admin.receiveList.filter(item => item.receive === 4).length > 0?
                                                                admin.receiveList.filter(item => item.receive === 4)[0].count : 0
                                                        }
                                                    </td>
                                                    <td style={{color: receiveList[0].color}}>
                                                        {
                                                            admin.receiveList.filter(item => item.receive === 1).length > 0?
                                                                admin.receiveList.filter(item => item.receive === 1)[0].count : 0
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {/*{[2,3].includes(userInfo.type) && (
                            <div className="d-flex justify-content-around">
                                <table className="table table-bordered border-dark text-center align-middle">
                                    <thead className="table-success">
                                    <tr>
                                        <th style={{width: "20%"}}>리콜팀</th>
                                        <th>총통화건수</th>
                                        <th>수신통화건수</th>
                                        <th>미수신통화건수</th>
                                        <th>통화완료</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {memberStoreList.map((store) => (
                                        <tr key={store.no}>
                                            <td>{store.name}</td>
                                            <td>
                                                {memberStoreReceive.map((receive) => (
                                                    store.no === receive.store_no? receive.receiveSum : ""
                                                ))}
                                            </td>
                                            <td>
                                                {memberStoreReceive.map((receive) => (
                                                    store.no === receive.store_no? receive.receiveCount : ""
                                                ))}
                                            </td>
                                            <td>
                                                {memberStoreReceive.map((receive) => (
                                                    store.no === receive.store_no? receive.unReceiveCount : ""
                                                ))}
                                            </td>
                                            <td>
                                                {memberStoreReceive.map((receive) => (
                                                    store.no === receive.store_no? receive.joinComplete : ""
                                                ))}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}*/}
                    </>)
                }

                <div className="mt-2">
                    <span>공지사항</span>
                    <div className="my-2">
                        <table className="table table-bordered border-dark text-center align-middle">
                            <thead className="table-primary">
                            <tr>
                                <th style={{width: "90%"}}>제목</th>
                                <th style={{width: "10%"}}>작성일자</th>
                            </tr>
                            </thead>
                            {noticeList && noticeList.map((notice, index) => (
                            <tbody key={notice.no}>
                            <tr>
                                <td>
                                    <Link className="nav-link" to={"#noticeTitle" + index} data-bs-toggle="collapse"
                                          aria-expanded="true" aria-controls={"noticeTitle" + index}>
                                        {notice.title}
                                    </Link>
                                </td>
                                <td>{moment(notice.createdAt).format("YYYY-MM-DD HH:mm")}</td>
                            </tr>
                            <tr className="collapse" id={"noticeTitle" + index}>
                                <td colSpan="3" className="text-start">
                                    {notice.cont}
                                </td>
                            </tr>
                            </tbody>
                            ))}
                        </table>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Home;