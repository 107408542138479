import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getAdminList, getMemberList} from "../redux/reducers/member";
import Pagination from "../components/common/Pagination";
import MemberList from "../components/MemberList";
import axios from "axios";
import ProgressModal from "../components/common/ProgressModal";


const MemberContainer = () => {

    const dispatch = useDispatch()
    const state = useSelector((state) => state.member)
    const [searchQuery, setSearchQuery] = useState("");
    const [isWait, setIsWait] = useState(false)

    const userInfo = useSelector((state) => state.userInfo);

    useEffect(() => {
        dispatch(getMemberList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery
        }))
    }, [])


    const handlePageChange = (page) => {
        dispatch(getMemberList({
            page: page,
            pageSize: state.pageSize,
            query: searchQuery
        }))
    }

    const changeQuery = (value) => {
        setSearchQuery(value)
    }

    const inputPress = (e) => {
        if(e.key === "Enter") {
            onSearch(e.target.value)
        }
    }

    const onSearch = (query) => {
        setSearchQuery(query)
        dispatch(getMemberList({
            page: state.page,
            pageSize: state.pageSize,
            query: query,
        }))
    }

    const onClickSearch = (e) => {

        dispatch(getMemberList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
        }))
    }

    const onRefresh = () => {
        dispatch(getMemberList({
            page: state.page,
            pageSize: state.pageSize,
            query: searchQuery,
        }))
    }

    const startIndex = ((state.page - 1) * state.pageSize) + 1;
    const currentMaxCount = state.totalCount < (startIndex + state.pageSize - 1)? state.totalCount : (startIndex + state.pageSize - 1)

    return <div>
        {state.isLoading || isWait && (<ProgressModal />)}
        <div className="container-fluid m-1 p-0" style={{
            display: "flex"
        }}>
            <p className="h5 ps-2">{ startIndex + "~" + currentMaxCount + " / " + state.totalCount}</p>

            <select
                className="form-select me-1"
                defaultValue={100}
                style={{
                    width: "100px"
                }}
                value={state.pageSize}
                onChange={e =>
                    dispatch(getMemberList({
                        page: state.page,
                        pageSize: parseInt(e.target.value),
                        query: searchQuery,
                    }))
                }
            >
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={500}>500</option>
                <option value={10000}>10000</option>
            </select>
            <Pagination totalCount={state.totalCount} pageSize={state.pageSize} currentPage={state.page} onPageChange={handlePageChange} />

            <div className="input-group me-1" style={{
                display: "flex",
                width: "300px"
            }}>
                <input style={{
                    width: "220px"
                }} type="text" className="form-control" id="search_edit" placeholder="검색어를 입력해주세요." onBlur={(e) => {
                    changeQuery(e.target.value)
                }} onKeyDown={inputPress} />
                <button style={{
                    width: "80px"
                }} type="submit" className="btn btn-primary" onClick={onClickSearch}>검색</button>
            </div>
        </div>

        <MemberList state={state} onRefresh={onRefresh}/>
    </div>
}

export default MemberContainer;