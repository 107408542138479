import React, {useState} from "react";
import axios from "axios";

const StoreAddComponent = () => {

    const [type, setType] = useState(0);
    const [name, setName] = useState("");
    const [memo, setMemo] = useState("");

    const typeChangeHandler = (e) => {
        setType(e.currentTarget.value);
    }
    const typeSelectList = [
        {no: 1, value : 1, name : "영업"},
        {no: 2, value : 2, name : "리콜"},
    ]

    const nameChangeHandler = (e) => {
        setName(e.currentTarget.value);
    }

    const memoHandler = (e) =>{
        setMemo(e.currentTarget.value);
    }

    const handleRegister = () => {
        if(type === 0) {
            alert("사용처를 선택바랍니다.")
        } else {
            axios.post('/admin/store/insert', {
                type: type,
                name: name,
                memo: memo,
            }).then((res) => {
                if(res.data.isInserted) {
                    alert('지점 등록완료')
                    window.location.reload()
                }
                else {
                    alert('등록실패')
                }
            })
        }
    }
    return (

        <div className="modal fade" id="storeAdd" tabIndex="-1" aria-hidden="true" >
            <div className="modal-dialog" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" >지점 추가</h1>
                    </div>

                    <div className="modal-body">
                        <select className="form-select mb-2" onChange={typeChangeHandler} value={type}>
                            <option key={0} value={0}>---사용처선택---</option>
                            {typeSelectList.map((item, index) => (
                                <option key={item.no} value={item.no}>{item.name}</option>
                            ))}
                        </select>
                        <div className="form-floating mb-2">
                            <input className="form-control" maxLength="12" type="text" placeholder="아이디" value={name} onChange={nameChangeHandler} />
                            <label className="col-form-label">지점 이름</label>
                        </div>

                        <div className="form-floating">
                            <textarea className="form-control" maxLength="50" type="text" placeholder="메모" value={memo} onChange={memoHandler} />
                            <label className="col-form-label">메모</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">취소</button>
                        <button type="button" className="btn btn-primary" onClick={handleRegister}>등록</button>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default StoreAddComponent;