import axios from "axios";

export const requestLogin = async (id, pw) => {
    return axios.post("/api/sign/login", {id: id, pw: pw})
}

export function getTotalCount() {
    return axios.get('/admin/phoneCheck/get_check_legnth', {
        params: {

        }
    })
}

export function searchPhone(query) {
    return axios.post('/admin/phoneCheck/query', {
        query: query,
    })
}

export function searchID(query) {
    return axios.post('/admin/phoneCheck/searchID', {
        query: query,
    })
}


//Member
export function fetchMemberList(page, pageSize, query) {
    return axios.post('/admin/member_upload/getMemberList', {
        page: page,
        pageSize: pageSize,
        query: query,
    })
}
export function fetchAdmins(store) {
    return axios.post('/admin/member_upload/getAdmins', {
        store: store,
    })
}
export function uploadMember(json, adminNo) {
    return axios.post('/admin/member_upload/upload', {
        json: json,
        adminNo: adminNo,
    })
}

//Business
export function fetchBusinessList(page, pageSize, query, fileName) {
    return axios.post('/admin/business_upload/getBusinessList', {
        page: page,
        pageSize: pageSize,
        query: query,
        fileName: fileName,
    })
}

//Overlap
export function fetchOverlapList(page, pageSize, query, fileName) {
    return axios.post('/admin/business_upload/getOverlapList', {
        page: page,
        pageSize: pageSize,
        query: query,
        fileName: fileName,
    })
}

//Black
export function fetchBlackList(page, pageSize, query, fileName) {
    return axios.post('/admin/business_upload/getBlackList', {
        page: page,
        pageSize: pageSize,
        query: query,
        fileName: fileName,
    })
}

//BusinessDB
export function fetchBusinessDBList(page, pageSize, query, titleNo, startDate, recycle) {
    return axios.post('/admin/businessDBList/getBusinessDBList', {
        page: page,
        pageSize: pageSize,
        query: query,
        titleNo: titleNo,
        startDate: startDate,
        recycle: recycle,
    })
}
export function fetchDBAdmins(store) {
    return axios.post('/admin/businessDBList/getDBAdmins', {
        store: store,
    })
}

export function fetchSharedBusinessList(page, pageSize, query, store, admin, startDate, title, shareType, receiveType, returnType) {
    return axios.post('/admin/business/getSharedBusinessList', {
        page: page,
        pageSize: pageSize,
        query: query,
        store: store,
        admin: admin,
        startDate: startDate,
        title: title,
        shareType: shareType,
        receiveType: receiveType,
        returnType: returnType,
    })
}
export function fetchSharedAdmins(store) {
    return axios.post('/admin/business/getSharedAdmins', {
        store: store,
    })
}

export function fetchUserBusinessList(page, pageSize, query, store, admin, title, receiveType) {
    return axios.post('/admin/business/get_business_list', {
        page: page,
        pageSize: pageSize,
        query: query,
        store: store,
        admin: admin,
        title: title,
        receiveType: receiveType,
    })
}
export function fetchUserAdmins(store) {
    return axios.post('/admin/business/get_employee', {
        store: store,
    })
}

export function fetchUserBusinessReceiveList(page, pageSize, query, store, admin) {
    return axios.post('/admin/business/getBusinessReceiveList', {
        page: page,
        pageSize: pageSize,
        query: query,
        store: store,
        admin: admin,
    })
}

export function fetchUserBusinessCompleteList(page, pageSize, query, store, admin) {
    return axios.post('/admin/business/getBusinessCompleteList', {
        page: page,
        pageSize: pageSize,
        query: query,
        store: store,
        admin: admin,
    })
}
